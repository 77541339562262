import React from 'react';
import { di } from 'react-magnetic-di';
import { Renderer } from 'view/common/forge-ui/renderer';
import { type ForgeAppRendererProps } from '@atlassian/ui-modifications-core';

export const ForgeAppRenderer = (props: ForgeAppRendererProps) => {
    di(Renderer);
    const { extension, ...restProps } = props;
    // @ts-expect-error - Types of property 'extension' are incompatible.
    return <Renderer extension={extension} {...restProps} />;
};
