import { buildModelsActionCreator } from 'epics/model/actions';
import type { EnrichedRequestCreateResponse, MediaApiUploadInformation } from 'rest/request-create';
import type { ErrorResponse } from 'epics/model/types';

export const FETCH_REQUEST_CREATE_MODEL = 'epic.model.FETCH_REQUEST_CREATE_MODEL';
export const FETCH_REQUEST_CREATE_SUCCESS = 'state.persisted.reqCreate.FETCH_REQUEST_CREATE_SUCCESS';
export const FETCH_REQUEST_CREATE_FAILURE = 'state.persisted.reqCreate.FETCH_REQUEST_CREATE_FAILURE';

export const RESET_REQUEST_CREATE_STATE = 'state.persisted.reqCreate.RESET_REQUEST_CREATE_STATE';

export const FETCH_REQUEST_CREATE_UPLOAD_MEDIA_TOKEN_ACTION =
    'state.persisted.reqCreate.FETCH_REQUEST_CREATE_UPLOAD_MEDIA_TOKEN_ACTION';
export const FETCH_REQUEST_CREATE_UPLOAD_MEDIA_TOKEN_SUCCESS =
    'state.persisted.reqCreate.FETCH_REQUEST_CREATE_UPLOAD_MEDIA_TOKEN_SUCCESS';
export const FETCH_REQUEST_CREATE_UPLOAD_MEDIA_TOKEN_FAILURE =
    'state.persisted.reqCreate.FETCH_REQUEST_CREATE_UPLOAD_MEDIA_TOKEN_FAILURE';
export const PROFORMA_FORM_DIRTY = 'state.persisted.reqCreate.PROFORMA_FORM_DIRTY';

export type FetchRequestCreateAction = ReturnType<typeof fetchRequestCreateAction>;

export const fetchRequestCreateAction = buildModelsActionCreator(FETCH_REQUEST_CREATE_MODEL, 'reqCreate');

export interface FetchRequestCreateSuccess {
    payload: EnrichedRequestCreateResponse;
    type: typeof FETCH_REQUEST_CREATE_SUCCESS;
}

export const fetchRequestCreateSuccess = (
    requestCreateResponse: EnrichedRequestCreateResponse
): FetchRequestCreateSuccess => ({
    payload: requestCreateResponse,
    type: FETCH_REQUEST_CREATE_SUCCESS,
});

export interface FetchRequestCreateFailure {
    type: typeof FETCH_REQUEST_CREATE_FAILURE;
    payload: {
        portalId: number;
        requestCreateId: number;
        error: ErrorResponse;
    };
}

export const fetchRequestCreateFailure = (
    portalId: number,
    requestCreateId: number,
    error: ErrorResponse
): FetchRequestCreateFailure => ({
    type: FETCH_REQUEST_CREATE_FAILURE,
    payload: {
        portalId,
        requestCreateId,
        error,
    },
});

export interface ResetRequestCreateStateAction {
    type: typeof RESET_REQUEST_CREATE_STATE;
    payload: Record<string, never>;
}

export interface FetchRequestCreateUploadMediaTokenAction {
    type: typeof FETCH_REQUEST_CREATE_UPLOAD_MEDIA_TOKEN_ACTION;
    payload: {
        portalId: number;
        requestCreateId: number;
    };
}

/**
 * @deprecated mediaApiUploadToken is moved to portal state
 */
export const fetchRequestCreateUploadMediaTokenAction = (
    portalId: number,
    requestCreateId: number
): FetchRequestCreateUploadMediaTokenAction => ({
    type: FETCH_REQUEST_CREATE_UPLOAD_MEDIA_TOKEN_ACTION,
    payload: {
        portalId,
        requestCreateId,
    },
});

export interface FetchRequestCreateUploadMediaTokenSuccess {
    type: typeof FETCH_REQUEST_CREATE_UPLOAD_MEDIA_TOKEN_SUCCESS;
    payload: {
        mediaApiUploadInformation: MediaApiUploadInformation;
        portalId: number;
        requestCreateId: number;
    };
}

/**
 * @deprecated mediaApiUploadToken is moved to portal state
 */
export const fetchRequestCreateUploadMediaTokenSuccess = (
    mediaApiUploadInformation: MediaApiUploadInformation,
    portalId: number,
    requestCreateId: number
): FetchRequestCreateUploadMediaTokenSuccess => {
    return {
        type: FETCH_REQUEST_CREATE_UPLOAD_MEDIA_TOKEN_SUCCESS,
        payload: {
            mediaApiUploadInformation,
            portalId,
            requestCreateId,
        },
    };
};

export interface FetchRequestCreateUploadMediaTokenFailure {
    type: typeof FETCH_REQUEST_CREATE_UPLOAD_MEDIA_TOKEN_FAILURE;
}

/**
 * @deprecated mediaApiUploadToken is moved to portal state
 */
export const fetchRequestCreateUploadMediaTokenFailure = (): FetchRequestCreateUploadMediaTokenFailure => {
    return {
        type: FETCH_REQUEST_CREATE_UPLOAD_MEDIA_TOKEN_FAILURE,
    };
};

export const resetRequestCreateStateAction = (): ResetRequestCreateStateAction => {
    return {
        type: RESET_REQUEST_CREATE_STATE,
        payload: {},
    };
};

export interface ProformaFormDirty {
    type: typeof PROFORMA_FORM_DIRTY;
    payload: {
        portalId: number;
        requestTypeId: number;
        isDirty: boolean;
    };
}

export const proformaFormDirtyAction = (payload: {
    isDirty: boolean;
    requestTypeId: number;
    portalId: number;
}): ProformaFormDirty => {
    return {
        payload,
        type: PROFORMA_FORM_DIRTY,
    };
};
