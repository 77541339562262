import React from 'react';
import emptyConfigPanel from 'assets/images/atlassian-icon.png';
import { useIntl } from 'react-intl-next';
import { Box, xcss } from '@atlaskit/primitives';
import messages from './messages';

export const ConfigPanelEmptyState = () => {
    const { formatMessage } = useIntl();
    return (
        <Box xcss={emptyConfigPanelStyles}>
            <img src={emptyConfigPanel} alt={formatMessage(messages.emptyConfigPanelImageAltText)} />
            {formatMessage(messages.emptyConfigPanel)}
        </Box>
    );
};

const emptyConfigPanelStyles = xcss({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 'space.400',
    textAlign: 'center',
});
