import { di } from 'react-magnetic-di';
import type { RecordSourceSelectorProxy } from 'relay-runtime';
import { commitLocalUpdate, ConnectionHandler, generateUniqueClientID } from 'relay-runtime';
import type RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';
import { CHANNEL_EXPIRY_TIME_24HRS } from '../../common/constants';
import type { AppendixMessageActions } from '../../common/types';
import { HelpSeekerMessageStatus } from '../../common/types';
import { parseJson } from '../../common/utils';
import { addNodeToConnection } from '../add-message-to-connection';
import type { JsmChatWebConversationActions } from '../omnichannel-subscription/__generated__/omnichannelSubscription.graphql';
import { ChannelMessageTypes } from 'unified-help-center-alpha/src/store/converse/types';

interface CleanFollowupsArgs {
    environment: RelayModernEnvironment;
    connectionId: string;
}

export const cleanFollowups = ({ environment, connectionId }: CleanFollowupsArgs) => {
    commitLocalUpdate(environment, (store) => {
        const connection = store.get(connectionId);
        if (!connection) return;
        const edges = connection.getLinkedRecords('edges');
        if (!edges) return;
        edges.forEach((edge) => {
            const node = edge.getLinkedRecord('node');
            if (!node) return;
            const authorType = node.getValue('authorType');
            if (authorType === 'Followup_Message') {
                ConnectionHandler.deleteNode(connection, node.getDataID());
            }
        });
    });
};

export const createFollowupMessageNode = (content: string[], store: RecordSourceSelectorProxy) => {
    const nodeId = generateUniqueClientID();
    const followupNode = store.create(nodeId, 'JsmChatCreateWebConversationMessage');

    followupNode.setValue(nodeId, 'id');
    followupNode.setValue('Followup_Message', 'authorType');
    followupNode.setValue(JSON.stringify(content), 'content');

    return nodeId;
};

export const generateAIResponseNodeId = (id: string) => {
    return `client::jsmChat::aiResponse::${id}`;
};

interface CreateHelpseekerMessageNodeArgs {
    content: string; // ADF
    store: RecordSourceSelectorProxy;
    nodeId: string;
    message: string | null; // message without ADF
}

export const createHelpseekerMessageNode = ({ content, store, nodeId, message }: CreateHelpseekerMessageNodeArgs) => {
    const node = store.create(nodeId, 'JsmChatCreateWebConversationMessage');
    node.setValue(nodeId, 'id');
    node.setValue('Reporter', 'authorType');
    node.setValue(content, 'content');
    node.setValue('ADF', 'contentType');
    node.setValue(null, 'appendices');
    node.setValue(message, 'message');
    return node;
};

interface AddOptimisticResponseToConnectionArgs {
    environment: RelayModernEnvironment;
    connectionId: string;
    content: string;
    nodeId: string;
    message: string;
}
export const addOptimisticResponseToConnection = ({
    environment,
    connectionId,
    content,
    nodeId,
    message,
}: AddOptimisticResponseToConnectionArgs) => {
    commitLocalUpdate(environment, (store) => {
        createHelpseekerMessageNode({ content, store, nodeId, message });
        addNodeToConnection({
            store,
            connectionId,
            recordId: nodeId,
            edgeName: 'JsmChatMessageEdge',
        });
    });
};

interface DeleteNodeFromConnectionArgs {
    environment: RelayModernEnvironment;
    connectionId: string;
    nodeId: string;
}
export const deleteNodeFromConnection = ({ environment, connectionId, nodeId }: DeleteNodeFromConnectionArgs) => {
    commitLocalUpdate(environment, (store) => {
        const connection = store.get(connectionId);
        if (!connection) return;
        const edges = connection.getLinkedRecords('edges');
        if (!edges) return;
        edges.forEach((edge) => {
            const node = edge.getLinkedRecord('node');
            if (!node) return;
            const authorType = node.getValue('id');
            if (authorType === nodeId) {
                ConnectionHandler.deleteNode(connection, node.getDataID());
                store.delete(node.getDataID());
            }
        });
    });
};

interface UpdateRelayRecordIdArgs {
    environment: RelayModernEnvironment;
    nodeId: string;
    newId: string;
}

export const updateRelayRecordId = ({ environment, nodeId, newId }: UpdateRelayRecordIdArgs) => {
    commitLocalUpdate(environment, (store) => {
        const node = store.get(nodeId);
        if (node) {
            node.setValue(newId, 'id');
        }
    });
};

interface AddInteractionMessageToConnectionProps {
    environment: RelayModernEnvironment;
    connectionId: string;
    message: string;
    nodeId: string;
    interactionMeta: InteractionMeta;
}
interface InteractionMeta {
    interactionType: string;
    selectedValue: string;
    jiraFieldId: string | undefined;
    message: string;
    messageId: string;
}

export const addInteractionMessageToConnection = ({
    environment,
    message,
    nodeId,
    connectionId,
    interactionMeta,
}: AddInteractionMessageToConnectionProps) => {
    commitLocalUpdate(environment, (store) => {
        const node = createHelpseekerMessageNode({ store, nodeId, message: interactionMeta.message, content: message });
        node.setValue('InteractionMessage', 'authorType');
        node.setValue(JSON.stringify(interactionMeta), 'interactionFieldMeta');
        addNodeToConnection({
            store,
            connectionId,
            recordId: nodeId,
            edgeName: 'JsmChatMessageEdge',
        });
    });
};

interface ConvertInteractionMessageToErrorMessageProps {
    nodeId: string;
    environment: RelayModernEnvironment;
    message: string;
}

export const convertInteractionMessageToErrorMessage = ({
    nodeId,
    environment,
    message,
}: ConvertInteractionMessageToErrorMessageProps) => {
    commitLocalUpdate(environment, (store) => {
        const node = store.get(nodeId);
        if (node) {
            node.setValue('InteractionError', 'authorType');
            node.setValue(message, 'content');
        }
    });
};

interface ResetTimerProps {
    environment: RelayModernEnvironment;
    expiry?: number;
    channelMessage?: ChannelMessageTypes;
}

export const resetTimer = ({
    environment,
    expiry = CHANNEL_EXPIRY_TIME_24HRS,
    channelMessage = ChannelMessageTypes.CHANNEL_EXPIRY,
}: ResetTimerProps) => {
    clearTimer(environment);
    const timer = setTimeout(() => {
        commitLocalUpdate(environment, (store) => {
            const root = store.getRoot();
            const jsmChatQuery = root.getOrCreateLinkedRecord('jsmChatConversationMeta', 'JsmChatConversationMeta');
            const connectionId = jsmChatQuery.getValue('connectionId') as string;
            if (!connectionId) return;
            const channelMessageNode = createChannelMessage(store, channelMessage);
            addNodeToConnection({
                store,
                connectionId: connectionId as string,
                recordId: channelMessageNode.getDataID(),
                edgeName: 'JsmChatMessageEdge',
            });
            jsmChatQuery.setValue(true, 'isConversationExpired');
            const connection = store.get(connectionId);
            if (!connection) return;
            const edges = connection.getLinkedRecords('edges');
            if (!edges) return;
            edges.forEach((edge) => {
                const node = edge.getLinkedRecord('node');
                if (!node) return;
                const authorType = node.getValue('authorType');
                if (authorType === 'Followup_Message') {
                    ConnectionHandler.deleteNode(connection, node.getDataID());
                }
            });
        });
    }, expiry);
    updateTimer(environment, timer as unknown as number);
};

export const updateTimer = (environment: RelayModernEnvironment, timer: number) => {
    commitLocalUpdate(environment, (store) => {
        const root = store.getRoot();
        const jsmChatQuery = root.getOrCreateLinkedRecord('jsmChatConversationMeta', 'JsmChatConversationMeta');
        jsmChatQuery.setValue(timer, 'timer');
    });
};

export const clearTimer = (environment: RelayModernEnvironment) => {
    di(clearTimeout, commitLocalUpdate);
    commitLocalUpdate(environment, (store) => {
        const root = store.getRoot();
        const jsmChatQuery = root.getOrCreateLinkedRecord('jsmChatConversationMeta', 'JsmChatConversationMeta');
        const timerId = (jsmChatQuery.getValue('timer') ?? 0) as number;
        clearTimeout(timerId);
    });
};

export const createChannelMessage = (store: RecordSourceSelectorProxy, type: ChannelMessageTypes) => {
    const nodeId = generateUniqueClientID();
    const channelMessageNode = store.create(nodeId, 'JsmChatCreateWebConversationMessage');
    channelMessageNode.setValue(nodeId, 'id');
    channelMessageNode.setValue('ChannelMessage', 'authorType');
    channelMessageNode.setValue(type, 'content');
    return channelMessageNode;
};

interface UpdateLastAIConversationAbortStatusProps {
    environment: RelayModernEnvironment;
    status: boolean;
}

export const updateLastAIConversationAbortStatus = ({
    environment,
    status,
}: UpdateLastAIConversationAbortStatusProps) => {
    commitLocalUpdate(environment, (store) => {
        const root = store.getRoot();
        const jsmChatQuery = root.getOrCreateLinkedRecord('jsmChatConversationMeta', 'JsmChatConversationMeta');
        const connectionId = jsmChatQuery.getValue('connectionId') as string | undefined;
        if (!connectionId) return;
        const connection = store.get(connectionId);
        if (!connection) return;
        const edges = connection.getLinkedRecords('edges');
        if (!edges) return;
        for (const edge of edges.reverse()) {
            const node = edge.getLinkedRecord('node');
            if (!node) return;
            const authorType = node.getValue('authorType');
            if (authorType === 'Assistant_Message') {
                const assistantMessageNode = node?.getOrCreateLinkedRecord(
                    'assistantMessage',
                    'JsmChatAssistantMessage'
                );
                assistantMessageNode.setValue(status, 'isAborted');
                return;
            }
        }
    });
};

interface updateMessageStatusProp {
    environment: RelayModernEnvironment;
}
export const updateMessageStatusToHelpseeker = ({ environment }: updateMessageStatusProp) => {
    commitLocalUpdate(environment, (store) => {
        const root = store.getRoot();
        const jsmChatQuery = root.getOrCreateLinkedRecord('jsmChatConversationMeta', 'JsmChatConversationMeta');
        jsmChatQuery.setValue(HelpSeekerMessageStatus.IDLE, 'aiAnswerStatus');
    });
};

interface UpdateActionRecordInConversationMessageProp {
    environment: RelayModernEnvironment;
    nodeId: string;
    action: AppendixMessageActions;
}
export const updateActionRecordInConversationMessage = ({
    environment,
    nodeId,
    action,
}: UpdateActionRecordInConversationMessageProp) => {
    commitLocalUpdate(environment, (store) => {
        const node = store.get(nodeId);
        if (!node) return;
        node.setValue(action, 'action');
    });
};

interface GetInteractionMetaFromNodeProp {
    environment: RelayModernEnvironment;
    nodeId: string;
}
export const getInteractionMetaFromNode = ({ environment, nodeId }: GetInteractionMetaFromNodeProp) => {
    return new Promise<InteractionMeta>((resolve, reject) => {
        commitLocalUpdate(environment, (store) => {
            const node = store.get(nodeId);
            if (!node) {
                reject(new Error('No Node found'));
                return;
            }
            const interactionMeta = node?.getValue('interactionFieldMeta');
            if (!interactionMeta) {
                reject(new Error('No InteractionMeta found'));
                return;
            }
            resolve(parseJson(interactionMeta) as InteractionMeta);
        });
    });
};

interface GetLastHelpSeekerMessageProp {
    environment: RelayModernEnvironment;
}

export const getLastHelpSeekerMessage = ({ environment }: GetLastHelpSeekerMessageProp) => {
    return new Promise<string>((resolve, reject) => {
        commitLocalUpdate(environment, (store) => {
            const root = store.getRoot();
            const jsmChatQuery = root.getOrCreateLinkedRecord('jsmChatConversationMeta', 'JsmChatConversationMeta');
            const connectionId = jsmChatQuery.getValue('connectionId') as string | undefined;
            if (!connectionId) {
                reject(new Error('No ConnectionID Found'));
                return;
            }
            const connection = store.get(connectionId);
            if (!connection) {
                reject(new Error('No Connection Found'));
                return;
            }
            const edges = connection.getLinkedRecords('edges');
            if (!edges) {
                reject(new Error('No Messages Found'));
                return;
            }
            for (const edge of edges.reverse()) {
                const node = edge.getLinkedRecord('node');
                const authorType = node?.getValue('authorType');
                if (authorType === 'Reporter') {
                    resolve(node?.getValue('message') as string);
                    return;
                }
            }
        });
    });
};

interface SetDraftMessageArgs {
    environment: RelayModernEnvironment;
    message: string | null;
}
export const setDraftMessage = ({ environment, message }: SetDraftMessageArgs) => {
    commitLocalUpdate(environment, (store) => {
        const root = store.getRoot();
        const jsmChatQuery = root.getOrCreateLinkedRecord('jsmChatConversationMeta', 'JsmChatConversationMeta');
        jsmChatQuery.setValue(message, 'draftMessage');
    });
};

interface HandleActionParamFromSubscription {
    action: JsmChatWebConversationActions | null | undefined;
    environment: RelayModernEnvironment;
    nodeId: string;
    connectionId?: string;
}

export const handleActionParamFromSubscription = ({
    action,
    environment,
    nodeId,
    connectionId,
}: HandleActionParamFromSubscription) => {
    // eslint-disable-next-line default-case
    switch (action) {
        case 'REDIRECT_TO_SEARCH':
            updateActionRecordInConversationMessage({
                environment,
                nodeId,
                action,
            });
            break;
        case 'CLOSE_CONVERSATION':
            resetTimer({ environment, expiry: 0, channelMessage: ChannelMessageTypes.CHANNEL_CONVERSATION_OVER });
            break;
        case 'DISABLE_INPUT':
            if (connectionId) {
                cleanFollowups({ environment, connectionId });
                updateOmnichannelInputStatus(environment, true);
            }
            break;
    }
};

export const updateOmnichannelInputStatus = (environment: RelayModernEnvironment, status: boolean) => {
    commitLocalUpdate(environment, (store) => {
        const root = store.getRoot();
        const jsmChatQuery = root.getOrCreateLinkedRecord('jsmChatConversationMeta', 'JsmChatConversationMeta');
        jsmChatQuery.setValue(status, 'disableInput');
    });
};

interface OmnichannelMeta {
    conversationId: string | undefined | null;
    connectionId: string | undefined | null;
    conversationContextAri: string | undefined | null;
    hasMessage: boolean | undefined | null;
    isReplyPending: boolean | undefined | null;
    aiAnswerStatus: string | undefined | null;
    draftMessage: string | undefined | null;
    isConversationExpired: boolean | undefined | null;
    isConversationClosed: boolean | undefined | null;
    disableInput: boolean | undefined | null;
    subscriptionId: string | undefined | null;
    greetingMessage: string | undefined | null;
    subscriptionTriggered: boolean | undefined | null;
}
export const fetchOmnichannelMeta = (environment: RelayModernEnvironment) => {
    return new Promise<OmnichannelMeta>((resolve, reject) => {
        commitLocalUpdate(environment, (store) => {
            const root = store.getRoot();
            if (!root) reject(new Error('No Root Found'));
            const jsmChatQuery = root.getOrCreateLinkedRecord('jsmChatConversationMeta', 'JsmChatConversationMeta');
            const conversationId = jsmChatQuery.getValue('conversationId') as string | undefined;
            const connectionId = jsmChatQuery.getValue('connectionId') as string | undefined;
            const conversationContextAri = jsmChatQuery.getValue('conversationContextAri') as string | undefined;
            const hasMessage = jsmChatQuery.getValue('hasMessage') as boolean | undefined;
            const isReplyPending = jsmChatQuery.getValue('isReplyPending') as boolean | undefined;
            const aiAnswerStatus = jsmChatQuery.getValue('aiAnswerStatus') as string | undefined;
            const draftMessage = jsmChatQuery.getValue('draftMessage') as string | undefined;
            const isConversationExpired = jsmChatQuery.getValue('isConversationExpired') as boolean | undefined;
            const isConversationClosed = jsmChatQuery.getValue('isConversationClosed') as boolean | undefined;
            const disableInput = jsmChatQuery.getValue('disableInput') as boolean | undefined;
            const subscriptionId = jsmChatQuery.getValue('subscriptionId') as string | undefined;
            const greetingMessage = jsmChatQuery.getValue('greetingMessage') as string | undefined;
            const subscriptionTriggered = jsmChatQuery.getValue('subscriptionTriggered') as boolean | undefined;
            subscriptionTriggered;
            resolve({
                conversationId,
                connectionId,
                conversationContextAri,
                hasMessage,
                isReplyPending,
                aiAnswerStatus,
                draftMessage,
                isConversationExpired,
                isConversationClosed,
                disableInput,
                subscriptionId,
                greetingMessage,
                subscriptionTriggered,
            } as const);
        });
    });
};

export const getConnectionIdFromStore = <T,>(store: RecordSourceSelectorProxy<T>): string | undefined => {
    const root = store.getRoot();
    const jsmChatQuery = root.getOrCreateLinkedRecord('jsmChatConversationMeta', 'JsmChatConversationMeta');
    return jsmChatQuery.getValue('connectionId') as string;
};

interface createGreetingMessageNodeArgs {
    content: string; // ADF
    store: RecordSourceSelectorProxy;
    nodeId: string;
    authorType: string;
}
export const createGreetingMessageNode = ({ content, store, nodeId, authorType }: createGreetingMessageNodeArgs) => {
    const node = store.create(nodeId, 'JsmChatCreateWebConversationMessage');
    node.setValue(nodeId, 'id');
    node.setValue(authorType, 'authorType');
    node.setValue(content, 'content');
    node.setValue('ADF', 'contentType');
    node.setValue(null, 'appendices');
    return node;
};
export const addGreetingMessageToConnection = ({
    environment,
    connectionId,
    nodeId,
}: Omit<AddOptimisticResponseToConnectionArgs, 'message' | 'content'>) => {
    commitLocalUpdate(environment, (store) => {
        di(addNodeToConnection);
        const root = store.getRoot();
        const jsmChatQuery = root.getOrCreateLinkedRecord('jsmChatConversationMeta', 'JsmChatConversationMeta');
        const content = (jsmChatQuery.getValue('greetingMessage') ?? '') as string;
        const isGreetingMessageAdded = jsmChatQuery.getValue('isGreetingMessageAdded') ?? false;
        if (!isGreetingMessageAdded) {
            const authorType = content !== '' ? 'VirtualAgent' : 'GreetingMessage';
            createGreetingMessageNode({ content, store, nodeId, authorType });

            addNodeToConnection({
                store,
                connectionId,
                recordId: nodeId,
                edgeName: 'JsmChatMessageEdge',
            });
            jsmChatQuery.setValue(true, 'isGreetingMessageAdded');
        }
    });
};
