/* eslint-disable relay/must-colocate-fragment-spreads */
/* eslint-disable relay/unused-fields */
import React, { useCallback, useEffect } from 'react';
import ErrorBoundary from '@helpCenter/view/error-boundary/error-boundary';
import { noop } from 'lodash';
import { LazySuspense } from 'react-loosely-lazy';
import { di } from 'react-magnetic-di';
import { useLazyLoadQuery, graphql, useRelayEnvironment } from 'react-relay';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { isFetchNetworkOrClientError } from '@atlassian/help-center-common-util/fetch';
import type { FetchError } from '@atlassian/help-center-common-util/fetch';
import { useOmnichannelMetaQuery } from '@atlassian/omnichannel/src/controllers/omnichannel-meta';
import { updateOmniChannelConversationMeta } from '@atlassian/omnichannel/src/controllers/omnichannel-update-conversation-meta';
import { ExperienceSuccess, useUFOComponentExperience } from '@atlassian/ufo';
import { generateUniqueSubscriptionID } from '../../controllers/subscription';
import type { omnichannelClientConnectionQuery } from './__generated__/omnichannelClientConnectionQuery.graphql';
import { omnichannelGetConversationExperience } from './experiences';

export const OmnichannelClientConnectionUtil = () => {
    di(
        useOmnichannelMetaQuery,
        updateOmniChannelConversationMeta,
        generateUniqueSubscriptionID,
        useUFOComponentExperience,
        ExperienceSuccess,
        useLazyLoadQuery
    );
    const metaData = useOmnichannelMetaQuery();
    const subscriptionId = metaData.subscriptionId;
    useUFOComponentExperience(omnichannelGetConversationExperience);
    const data = useLazyLoadQuery<omnichannelClientConnectionQuery>(
        graphql`
            query omnichannelClientConnectionQuery($conversationId: ID!, $isEmptyConversation: Boolean!) {
                jsmChat @skip(if: $isEmptyConversation) {
                    getWebConversation(last: 1, conversationId: $conversationId)
                        @optIn(to: "JsmChatVAConversationAPI")
                        @connection(key: "Connection__getWebConversation") {
                        __id
                        edges {
                            __typename
                            __id
                            ...messageRendererFragment
                        }
                    }
                }
            }
        `,
        {
            conversationId: subscriptionId ?? '',
            isEmptyConversation: !subscriptionId,
        }
    );

    const newConnectionId = data.jsmChat?.getWebConversation?.__id;
    const environment = useRelayEnvironment();

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        generateUniqueSubscriptionID({ environment });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        omnichannelGetConversationExperience.start();
    }, [environment]);

    useEffect(() => {
        if (newConnectionId && metaData.connectionId !== newConnectionId) {
            updateOmniChannelConversationMeta({ environment, connectionId: newConnectionId });
        }
    }, [environment, metaData.connectionId, newConnectionId]);

    return <ExperienceSuccess experience={omnichannelGetConversationExperience} />;
};

interface OmnichannelClientConnectionProp {
    renderError?: (error: Error | FetchError) => React.ReactNode;
}
export const OmnichannelClientConnection = ({ renderError }: OmnichannelClientConnectionProp) => {
    di(OmnichannelClientConnectionUtil, ErrorBoundary);
    const onError = useCallback((error: Error | FetchError) => {
        if (isFetchNetworkOrClientError(error as FetchError)) {
            omnichannelGetConversationExperience
                .failure({
                    metadata: {
                        error: error?.message,
                        statusCode: (error as FetchError)?.status,
                    },
                })
                .then(noop)
                .catch(noop);
        }
    }, []);
    return (
        <ErrorBoundary
            packageName={ScreenName.CONVERSATION_PAGE}
            id="omnichannel-helpcenter-conversation"
            onError={onError}
            renderError={renderError}
        >
            <LazySuspense fallback={null}>
                <OmnichannelClientConnectionUtil />
            </LazySuspense>
        </ErrorBoundary>
    );
};
