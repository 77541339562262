import React from 'react';
import { di } from 'react-magnetic-di';
import { usePathParam } from 'react-resource-router';
import { HelpCenterContainer } from 'view/help-center/container';
import { useUFOComponentExperience } from '@atlassian/ufo';
import { ServiceHubForm } from '../service-hub-form';
import { csmHelpCenterFormPickerExperience } from './experiences';

export const CsmHelpCenter = () => {
    di(usePathParam, ServiceHubForm);
    useUFOComponentExperience(csmHelpCenterFormPickerExperience);

    const [requestIdParam] = usePathParam('requestId');

    return (
        <HelpCenterContainer data-testId="csm-help-center.container">
            <ServiceHubForm formId={requestIdParam} ufoExperience={csmHelpCenterFormPickerExperience} />
        </HelpCenterContainer>
    );
};
