import type { Action } from 'react-sweet-state';
import { createActionsHook, createContainer, createStateHook, createStore } from 'react-sweet-state';
import { RequestCreateViewConfiguration } from '../../utils/configuration';
import type { AdjustedField, InitializeFieldPayload, ProcessChangePayload } from './types';

export const requestCreateViewConfiguration = new RequestCreateViewConfiguration();

export function getSupportedFieldConfiguration(_viewType: string, fieldType: string) {
    return requestCreateViewConfiguration.getSupportedFieldsConfiguration()[fieldType];
}

export const isFieldTypeSupported = (_viewType: string, fieldType: string) => {
    return requestCreateViewConfiguration.getSupportedFieldTypes().includes(fieldType);
};

export const FakeUIMStoreContainer = createContainer();

interface State {
    formData: Record<string, InitializeFieldPayload>;
    appliedChanges: Record<string, AdjustedField> | null;
}

const actions = {
    initializeFields:
        (payload: InitializeFieldPayload[]): Action<State, void, void> =>
        ({ setState }) => {
            const formData = payload.reduce<Record<string, InitializeFieldPayload>>((acc, field) => {
                acc[field.field.fieldId] = field;

                return acc;
            }, {});

            setState({ formData });
        },
    applyOverrides:
        ({
            fieldId,
            prop,
            value,
        }: {
            fieldId: string;
            prop: string;
            value: string | number | null;
        }): Action<State, void, void> =>
        ({ setState, getState }) => {
            const initField = getState().formData[fieldId]?.field;
            if (!initField) {
                return;
            }
            const { fieldType, allowedValues } = initField;

            const fieldConfig = requestCreateViewConfiguration.getSupportedFieldsConfiguration();

            if (!fieldConfig[fieldType]) {
                return;
            }

            const mappedValue =
                fieldConfig[fieldType].publicToInternalTransformers?.[prop]?.(value, allowedValues) ?? value;
            const mappedProperty = fieldConfig[fieldType].propertyMapping?.[prop] ?? prop;

            const prevState = getState().appliedChanges ?? {};
            setState({
                appliedChanges: {
                    ...prevState,
                    [fieldId]: {
                        ...prevState?.[fieldId],
                        [mappedProperty]: mappedValue,
                    },
                },
            });
        },
    clearAppliedValue:
        (fieldId: string): Action<State, void, void> =>
        ({ setState, getState }) => {
            const prevState = getState().appliedChanges ?? {};

            if (prevState[fieldId]) {
                const { value, ...otherFieldChanges } = prevState[fieldId];

                setState({
                    appliedChanges: {
                        ...prevState,
                        [fieldId]: otherFieldChanges,
                    },
                });
            }
        },
    processChange:
        (payload: ProcessChangePayload): Action<State, void, void> =>
        () => {
            // dummy action. Will be expanded to process change in the future
            payload;
        },
};

type Actions = typeof actions;

const FakeUIMStore = createStore<State, Actions>({
    actions,
    initialState: {
        formData: {},
        appliedChanges: null,
    },
    name: 'fake-uim-store',
    containedBy: FakeUIMStoreContainer,
    handlers: {
        onInit:
            () =>
            ({ dispatch, getState }) => {
                // @ts-expect-error
                window.UIM = {
                    logInitializedFields: () => {
                        // eslint-disable-next-line no-console
                        console.table(getState().formData);
                    },
                    logAppliedOverrides: () => {
                        // eslint-disable-next-line no-console
                        console.table(getState().appliedChanges);
                    },
                    change: (fieldId: string, prop: string, value: string | number | null) => {
                        dispatch(actions.applyOverrides({ fieldId, prop, value }));
                    },
                };
            },
    },
});

export const useAdjustedFields = createStateHook(FakeUIMStore, { selector: (state) => state.appliedChanges });
export const useStoreActions = createActionsHook(FakeUIMStore);
