import React, { useState, useCallback } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { isCSMHelpCenter } from '@helpCenter/util/advanced-help-center';
import ErrorBoundary from '@helpCenter/view/error-boundary/error-boundary';
import { isUseHelpCenterRefresh } from 'feature-flags';
import { injectIntl } from 'react-intl-next';
import type { IntlShape, WrappedComponentProps } from 'react-intl-next';
import { LazySuspense } from 'react-loosely-lazy';
import { di } from 'react-magnetic-di';
import type { Category } from 'rest/category';
import type { PortalAnnouncement } from 'rest/portal';
import { BlockedAppsBanner } from 'view/blocked-apps-banner';
import { ExternalLinks } from 'view/external-links';
import { ForgeFooterModule } from 'view/forge-ui/portal-footer';
import { ForgeHeaderModule } from 'view/forge-ui/portal-header';
import { PaperContent } from 'view/layout/paper';
import { RequestCreateFormHelpText } from 'view/portal-home/request-create-form-help-text';
import { LazyPortalSettingsAnnouncementSidebar } from 'view/portal-settings-announcement-sidebar';
import { LazyPortalSettingsSidebar } from 'view/portal-settings-sidebar';
import { RequestCreateForm } from 'view/request-create-form';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { AnnouncementBanner } from '@atlassian/help-center-common-component/announcement-banner';
import { CategoryList } from '@atlassian/help-center-common-component/category-list';
import { PORTAL_HOME, REQUEST_CREATE } from '@atlassian/help-center-common-component/connect-fragment';
import {
    HeaderConnectFragments,
    FooterConnectFragments,
} from '@atlassian/help-center-common-component/connect-fragments-on-all-pages';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { DocumentTitle } from '@atlassian/help-center-common-component/document-title';
import { withErrorPage } from '@atlassian/help-center-common-component/error-page';
import { FORGE_PORTAL_HOME, FORGE_REQUEST_CREATE } from '@atlassian/help-center-common-component/forge-ui/constants';
import { FormPicker } from '@atlassian/help-center-common-component/form-picker';
import { LoadingContainer } from '@atlassian/help-center-common-component/loading-container';
import { SkeletonLoadingContainer } from '@atlassian/help-center-common-component/skeleton-loading-container';
import { isKoshEnabledForDefaultHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { getCloudId } from '@atlassian/help-center-common-util/meta';
import Redirect from '@atlassian/help-center-common-util/redirect';
import { SidebarToggle } from '@atlassian/help-center-common-util/sidebar-toggle';
import { useVirtualAgentAvailability } from '@atlassian/help-center-virtual-agent/src/services/virtual-agent-availability';
import { VirtualAgentBanner } from '@atlassian/help-center-virtual-agent-banner';
import { UiModificationsEntryPoint } from '@atlassian/jsm-ui-modifications-view-request-create/src/ui/entry-point';
import {
    LearnByDoing,
    REFERRER_QUERY_PARAM,
    shouldShowLearnByDoingBanner,
} from '@atlassian/learn-by-doing-create-request';
import { useQueryParams } from '../../search/common';
import { HeaderSinglePortalMode } from '../header-single-portal-mode';
import i18n from './messages';
export interface Props {
    requestTypeName: string | undefined;
    requestTypeBelongsToGroup: boolean;
    portalName: string | undefined;
    portalId?: number;
    projectId?: number;
    loading: boolean;
    requestTypeId: number | undefined;
    requestGroupId: number | undefined;
    sideBarOpen: boolean;
    announcement: PortalAnnouncement | undefined;
    categories: Category[] | undefined;
    canEditAnnouncement?: boolean;
    isUsingLanguageSupport?: boolean;
    isProjectSimplified: boolean;
    isBrandingLoaded?: boolean;
    isLoggedIn: boolean;
    canCustomiseHelpCenter: boolean;
    isProjectAdmin: boolean;
    isAtlassianManagedAccount: boolean;
    defaultLanguageDisplayName?: string;
    contactLink?: string;
    toggleSidebar: (value?: boolean) => void;
    screenName?: ScreenName;
}

const validateRequestGroupIdPresent = (requestGroupId?: number) => {
    if (requestGroupId === -1) {
        return false;
    }
    return !!requestGroupId;
};

const getConnectPageType = (requestTypeId: number | undefined) => {
    if (requestTypeId) return REQUEST_CREATE;
    return PORTAL_HOME;
};

const getForgePageType = (requestTypeId: number | undefined) => {
    if (requestTypeId) return FORGE_REQUEST_CREATE;
    return FORGE_PORTAL_HOME;
};

const getPortalHomeTitle = ({
    intl,
    portalName,
    requestTypeName,
    requestGroupId,
}: {
    intl: IntlShape;
    portalName: string;
    requestTypeName?: string;
    requestGroupId?: number;
}) => {
    if (requestTypeName) {
        return intl.formatMessage(i18n.requestTypePageTitle, {
            portalName,
            requestTypeName,
        });
    }

    if (requestGroupId) {
        return intl.formatMessage(i18n.portalGroupPageTitle, {
            portalName,
        });
    }

    return intl.formatMessage(i18n.portalPageTitle, {
        portalName,
    });
};

const PortalHome = ({
    intl,
    requestTypeName,
    requestTypeBelongsToGroup,
    portalName,
    portalId,
    projectId,
    loading,
    requestTypeId,
    requestGroupId,
    sideBarOpen,
    announcement,
    categories,
    canEditAnnouncement,
    isProjectSimplified,
    isBrandingLoaded,
    isLoggedIn,
    canCustomiseHelpCenter,
    isProjectAdmin,
    isAtlassianManagedAccount,
    toggleSidebar,
    screenName,
}: Props & WrappedComponentProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { [REFERRER_QUERY_PARAM]: referrer } = useQueryParams();
    di(useVirtualAgentAvailability, ExternalLinks);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const openSidebar = useCallback(() => toggleSidebar(true), [toggleSidebar]);
    const closeSidebar = useCallback(() => toggleSidebar(false), [toggleSidebar]);

    const onSubmit = useCallback(() => setIsSubmitting(true), [setIsSubmitting]);
    const onSubmitComplete = useCallback(() => setIsSubmitting(false), [setIsSubmitting]);

    const shouldRenderAsPortalAnnouncement = (): boolean => {
        // for simplified projects, render side bar as announcement for agents with permission
        if (isProjectSimplified && !isProjectAdmin && canEditAnnouncement) {
            return true;
        }
        // in classic projects render sidebar as announcement for users who can't modify helpcenter, but can edit announcements
        if (!isProjectSimplified && !canCustomiseHelpCenter && canEditAnnouncement) {
            return true;
        }
        return false;
    };

    const renderSidebar = () => {
        if (!canEditAnnouncement) return null;

        if (isKoshEnabledForDefaultHelpCenter() && !isBrandingLoaded) {
            // since check in shouldRenderAsPortalAnnouncement is dependent on "canCustomiseHelpCenter" which is coming from helpCenter model, we need to make sure branding relay call is completed
            return null;
        }

        // Render the new 'next-gen' side bar, either as just the Announcement for agents with permission or with all customisation features available for admins
        if (shouldRenderAsPortalAnnouncement()) {
            return (
                <LazySuspense fallback={null}>
                    <LazyPortalSettingsAnnouncementSidebar
                        isOpen={sideBarOpen}
                        onOpen={openSidebar}
                        onClose={closeSidebar}
                    />
                </LazySuspense>
            );
        }

        return (
            <LazySuspense fallback={null}>
                <LazyPortalSettingsSidebar
                    isProjectSimplified={isProjectSimplified}
                    isOpen={sideBarOpen}
                    onOpen={openSidebar}
                    onClose={closeSidebar}
                />
            </LazySuspense>
        );
    };

    const key = String(requestTypeId);

    const title =
        portalName &&
        getPortalHomeTitle({
            intl,
            portalName,
            requestTypeName,
            requestGroupId,
        });

    const shouldRenderDropdown = !!requestGroupId || !categories || !categories.length;
    const shouldShowRequestsInBreadcrumbs = !!shouldRenderDropdown && categories && !!categories.length;
    const isExternalLinksVisible = projectId && !validateRequestGroupIdPresent(requestGroupId) && !requestTypeId;
    const shouldShowSkeleton = projectId && requestTypeId; // show only on request create page (for now)
    const LoadingIndicator = shouldShowSkeleton ? SkeletonLoadingContainer : LoadingContainer;
    const cloudId = getCloudId();
    const { isVirtualAgentEnabled } = useVirtualAgentAvailability({ isLoggedIn, cloudId, projectId });
    const shouldShowLearnByDoingExperimentBanner = shouldShowLearnByDoingBanner(isProjectAdmin, referrer);

    di(isCSMHelpCenter, Redirect, UiModificationsEntryPoint);
    if (isCSMHelpCenter(getEnv().helpCenterType)) {
        return <Redirect to={'/'} />;
    }

    return (
        <DocumentTitle title={title} ignoreBaseTitle>
            <>
                <LoadingIndicator isLoading={loading} enableUFOHold ufoHoldName="portal-home-dumb-fallback">
                    {!!announcement && !isUseHelpCenterRefresh() && (
                        <AnnouncementBanner
                            actionSubjectId="portalAnnouncementBanner"
                            header={announcement.userLanguageHeader}
                            messageHtml={announcement.userLanguageMessageWiki}
                            InnerContainerComponent={AnnouncementInnerContainer}
                        />
                    )}
                    {requestGroupId && portalId && requestTypeId && !requestTypeBelongsToGroup && (
                        <Redirect to={`/portal/${portalId}/group/${requestGroupId}`} push={false} />
                    )}
                    <AnalyticsContext
                        data={{
                            attributes: {
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                [REFERRER_QUERY_PARAM]: referrer,
                            },
                        }}
                    >
                        {shouldShowLearnByDoingExperimentBanner && <LearnByDoing screenName={screenName} />}

                        <PaperContent>
                            <UiModificationsEntryPoint portalId={portalId} requestTypeId={requestTypeId} />
                            <HeaderSinglePortalMode showRequestsInBreadcrumbs={shouldShowRequestsInBreadcrumbs} />
                            {projectId && (
                                <BlockedAppsBanner
                                    page={getForgePageType(requestTypeId)}
                                    isAtlassianManagedAccount={isAtlassianManagedAccount}
                                    projectId={projectId}
                                />
                            )}
                            <HeaderConnectFragments
                                page={getConnectPageType(requestTypeId)}
                                portalId={portalId}
                                requestTypeId={requestTypeId}
                            />
                            <ForgeHeaderModule
                                page={getForgePageType(requestTypeId)}
                                projectId={projectId}
                                requestTypeId={requestTypeId}
                                portalId={portalId}
                            />
                            {isVirtualAgentEnabled && (
                                <Box xcss={virtualBannerStyles}>
                                    <VirtualAgentBanner cloudId={cloudId} portalId={portalId} />
                                </Box>
                            )}
                            {!requestGroupId && <StyledCategoryList portalId={portalId} />}
                            {shouldRenderDropdown && (
                                <StyledFormPicker
                                    // Disabling existing violations, should be fixed when revisited.
                                    // eslint-disable-next-line jsx-a11y/no-autofocus
                                    autoFocus
                                    portalId={portalId}
                                    requestGroupId={requestGroupId}
                                    requestTypeId={requestTypeId}
                                    isDisabled={isSubmitting}
                                />
                            )}
                            <RequestCreateFormHelpText portalId={portalId} requestTypeId={requestTypeId} />
                            {/* Using key to force unmount form in order to avoid field values are still present when navigate to different request type.
                The reason is react-select, date picker manages its own state and our components store async call result in state such as form field error. Hence, resetting the form wont clear their state */}
                            <RequestCreateForm
                                portalId={portalId}
                                requestTypeId={requestTypeId}
                                key={key}
                                onSubmit={onSubmit}
                                onSubmitComplete={onSubmitComplete}
                            />
                            {isExternalLinksVisible && (
                                <ExternalLinksListWrapper>
                                    <ExternalLinks projectId={projectId} />
                                </ExternalLinksListWrapper>
                            )}
                            <FooterConnectFragments
                                page={getConnectPageType(requestTypeId)}
                                portalId={portalId}
                                requestTypeId={requestTypeId}
                            />
                            <ForgeFooterModule
                                page={getForgePageType(requestTypeId)}
                                projectId={projectId}
                                requestTypeId={requestTypeId}
                                portalId={portalId}
                            />
                        </PaperContent>
                    </AnalyticsContext>
                    <ErrorBoundary
                        packageName={ScreenName.HELP_CENTER_CUSTOMIZE_ANNOUNCEMENT_SIDEBAR}
                        id={'portal-home-sidebar'}
                    >
                        {sideBarOpen && renderSidebar()}
                    </ErrorBoundary>
                    <SidebarToggle openSidebar={openSidebar} closeSidebar={closeSidebar} />
                </LoadingIndicator>
            </>
        </DocumentTitle>
    );
};

export const PortalHomeDumb = withErrorPage(PortalHome, { isEmptyTraceIdLogExcluded: true });

export default injectIntl(PortalHomeDumb);

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const AnnouncementInnerContainer = styled(PaperContent)`
    /* Reset top padding from the paper content. */
    padding-top: 0;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ExternalLinksListWrapper = styled.div`
    margin-top: ${token('space.400', '32px')};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const StyledFormPicker = styled(FormPicker)`
    margin: ${token('space.400', '32px')} 0 ${token('space.100', '8px')};
    position: relative;
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const StyledCategoryList = styled(CategoryList)`
    margin-top: ${token('space.400', '32px')};
`;

const virtualBannerStyles = xcss({
    marginTop: token('space.300', '24px'),
    marginBottom: token('space.200', '16px'),
});
