/* eslint-disable @typescript-eslint/no-floating-promises */
import { graphql, requestSubscription, commitLocalUpdate } from 'relay-runtime';
import type RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';
import type { UHStreamMessage } from '@atlassian/conversation-assistant/dist/types/services/unified-help/types';
import { getRelayDataId } from '@atlassian/help-center-common-util/relay';
import { parseJson } from '@atlassian/omnichannel/src/common/utils';
import {
    addNodeToConnection,
    addNodeToCurrentConnection,
} from '@atlassian/omnichannel/src/controllers/add-message-to-connection';
import { updateStreamingMessageInStore } from '@atlassian/omnichannel/src/controllers/ai-streaming';
import { updateOmniChannelConversationMeta } from '@atlassian/omnichannel/src/controllers/omnichannel-update-conversation-meta';
import {
    handleActionParamFromSubscription,
    generateAIResponseNodeId,
    getConnectionIdFromStore,
} from '@atlassian/omnichannel/src/controllers/store-actions';
import type { communicationChannelSubscription } from './__generated__/communicationChannelSubscription.graphql';
import {
    firstIntentReply,
    initialAIMessageExperience,
    initialMessageOmnichannelV2Experience,
    subscriptionReplyExperience,
} from './experiences';

const SubscriptionGL = graphql`
    subscription communicationChannelSubscription($conversationId: ID!) {
        jsmChat {
            updateConversation(conversationId: $conversationId) @optIn(to: "JsmChatVAConversationAPI") {
                result {
                    __typename
                    ... on JsmChatWebSubscriptionEstablishedPayload {
                        id
                    }
                    ... on JsmChatWebConversationUpdateQueryError {
                        identifier
                        message
                    }
                }
                action
                conversation {
                    node {
                        id
                        authorType
                        content
                        contentType
                        appendices {
                            __typename
                            ... on JsmChatJiraFieldAppendix {
                                fieldId
                                requestTypeId
                                jiraProjectId
                            }
                            ... on JsmChatOptionAppendix {
                                options {
                                    id
                                    label
                                }
                            }
                            ... on JsmChatDropdownAppendix {
                                placeholder
                                options {
                                    id
                                    label
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

interface EstablishSubscriptionToOmnichannel {
    subscriptionId: string;
    environment: RelayModernEnvironment;
}
export const establishSubscriptionToOmnichannel = ({
    environment,
    subscriptionId,
}: EstablishSubscriptionToOmnichannel) => {
    subscriptionReplyExperience.start();
    return requestSubscription<communicationChannelSubscription>(environment, {
        onNext: (data) => {
            if (data?.jsmChat?.updateConversation?.result?.__typename === 'JsmChatWebSubscriptionEstablishedPayload') {
                subscriptionReplyExperience.success();
                return;
            }
            if (!data?.jsmChat?.updateConversation?.conversation) {
                updateOmniChannelConversationMeta({ environment, isReplyPending: false });
                return;
            }
            const node = data.jsmChat.updateConversation.conversation.node;
            if (!node) return;

            const recordId = getRelayDataId(node?.id, 'JsmChatCreateWebConversationMessage') as string;
            const action = data.jsmChat.updateConversation.action;

            handleActionParamFromSubscription({
                action,
                environment,
                connectionId: undefined,
                nodeId: recordId,
            });

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (node.content?.aiResponse) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                const aiResponse = node.content?.aiResponse;
                const aiResponseNodeId = generateAIResponseNodeId(recordId);
                commitLocalUpdate(environment, (store) => {
                    let aiResponseNode = store.get(aiResponseNodeId);
                    if (!aiResponseNode) {
                        aiResponseNode = store.create(aiResponseNodeId, 'JsmChatCreateWebConversationMessage');
                        aiResponseNode.setValue(aiResponseNodeId, 'id');
                        aiResponseNode.setValue('Assistant_Message', 'authorType');
                        const connectionId = getConnectionIdFromStore(store);
                        if (connectionId) {
                            addNodeToCurrentConnection({
                                store,
                                connectionId,
                                recordId: aiResponseNodeId,
                            });
                        }
                    }
                    store.delete(recordId);
                });

                const streamingMessages = parseJson(aiResponse) as UHStreamMessage[];
                streamingMessages?.forEach((streamingMessage) => {
                    updateStreamingMessageInStore({
                        environment,
                        streamingMessage,
                        connectionId: undefined,
                        recordId: aiResponseNodeId,
                    });
                });
                initialAIMessageExperience.success();
            } else {
                if (!action || action !== 'GREETING_MESSAGE') {
                    firstIntentReply.success();
                    initialMessageOmnichannelV2Experience.success();
                }
                commitLocalUpdate(environment, (store) => {
                    const connectionId = getConnectionIdFromStore(store);
                    if (connectionId) {
                        addNodeToConnection({
                            store,
                            connectionId,
                            recordId,
                            edgeName: 'JsmChatMessageEdge',
                        });
                    }
                });
            }
            if (!action || action !== 'GREETING_MESSAGE') {
                updateOmniChannelConversationMeta({ environment, isReplyPending: false });
            }
        },
        subscription: SubscriptionGL,
        variables: {
            conversationId: subscriptionId,
        },
    });
};
