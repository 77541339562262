import { isLocalStorageEnabledFromBrowserStorageControl } from 'feature-flags';
import { AtlBrowserStorageLocal } from '@atlassian/browser-storage-controls';
import { safeAccessWithErrorHandler } from '../safe-access-with-error-handler';

const getLocalStorageItem = (key: string, errorHandler?: () => void) => {
    return safeAccessWithErrorHandler(
        () =>
            !isLocalStorageEnabledFromBrowserStorageControl()
                ? localStorage.getItem(key)
                : AtlBrowserStorageLocal.getItem(key),
        errorHandler
    );
};

const setLocalStorageItem = (key: string, value: string, errorHandler?: () => void) => {
    return safeAccessWithErrorHandler(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        !isLocalStorageEnabledFromBrowserStorageControl()
            ? localStorage.setItem(key, value)
            : AtlBrowserStorageLocal.setItem(key, value);
    }, errorHandler);
};

const removeLocalStorageItem = (key: string, errorHandler?: () => void) => {
    return safeAccessWithErrorHandler(
        () =>
            !isLocalStorageEnabledFromBrowserStorageControl()
                ? localStorage.removeItem(key)
                : AtlBrowserStorageLocal.removeItem(key),
        errorHandler
    );
};

export { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem };
