import * as Sentry from '@sentry/browser';
import { eventFromUnknownInput } from '@sentry/browser/dist/eventbuilder';
import { isEntitlementLogEnabled } from 'feature-flags';
import { isNil } from 'lodash';
import { getEnv, EnvType } from '@atlassian/help-center-common-util/env';

export interface AdditionalErrorData {
    message?: string;
    errorBoundaryId?: string;
    errorBoundaryPackageName?: string;
    teamName?: string;
}

export const isSentryEnabled = () => {
    const env = getEnv();

    if (env.environment === EnvType.STAGING) {
        return process.env.NODE_ENV === 'production';
    }

    return env.environment === EnvType.PROD;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reportError = (exception: any, additionalErrorData?: AdditionalErrorData) => {
    // eslint-disable-next-line no-console
    console.error('reportError', exception, additionalErrorData);

    if (isSentryEnabled()) {
        if (additionalErrorData && isNil(additionalErrorData.message) && exception instanceof Error) {
            Sentry.withScope((scope) => {
                const { teamName, errorBoundaryId, errorBoundaryPackageName: packageName } = additionalErrorData;

                if (errorBoundaryId) {
                    scope.setTag('errorBoundaryId', errorBoundaryId);
                }

                if (packageName) {
                    scope.setTag('packageName', packageName);
                }

                if (teamName && isEntitlementLogEnabled()) {
                    scope.setTag('teamName', teamName);
                }

                const event = eventFromUnknownInput(exception);
                Sentry.captureEvent({ ...event, message: additionalErrorData?.message || event.message });
            });
        } else {
            const event = eventFromUnknownInput(exception);
            Sentry.captureEvent({ ...event, message: additionalErrorData?.message || event.message });
        }
    }
};
