import { buildModelsActionCreator } from 'epics/model/actions';
import { removeOnlyPortalFromPortalModelAPIEnabled } from 'feature-flags';
import type {
    PortalResponse,
    PortalBrandingResponseWithoutId,
    PortalAnnouncementUpdatePayload,
    PortalAnnouncementsUpdateResponse,
    MediaApiUploadInformation,
} from 'rest/portal';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

import type { UFOExperience } from '@atlassian/ufo';
import type { ErrorResponse } from 'epics/model/types';

import type { ModelTypeOptions } from 'rest/models/types';

export const FETCH_PORTAL_MODEL = 'epic.model.FETCH_PORTAL_MODEL';
export const FETCH_PORTAL_SUCCESS = 'state.persisted.portals.FETCH_PORTAL_SUCCESS';
export const FETCH_PORTAL_FAILURE = 'state.persisted.portals.FETCH_PORTAL_FAILURE';

export const UPDATE_PORTAL_ANNOUNCEMENT_MODEL = 'epic.portal-announcements.UPDATE_PORTAL_ANNOUNCEMENT_MODEL';
export const UPDATE_PORTAL_ANNOUNCEMENT_SUCCESS = 'state.persisted.portals.UPDATE_PORTAL_ANNOUNCEMENT_SUCCESS';
export const UPDATE_PORTAL_ANNOUNCEMENT_FAILURE = 'state.persisted.portals.UPDATE_PORTAL_ANNOUNCEMENT_FAILURE';

export const FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_ACTION = 'state.persisted.portal.FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_ACTION';
export const FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_SUCCESS = 'state.persisted.portal.FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_SUCCESS';
const FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_FAILURE = 'state.persisted.portal.FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_FAILURE';

export const UPDATE_PORTAL_ANNOUNCEMENTS_UI_STATE =
    'state.ui.portal-announcements.UPDATE_PORTAL_ANNOUNCEMENTS_UI_STATE';

export type PortalWithThemeResponse = PortalResponse & PortalBrandingResponseWithoutId;

export type FetchPortalAction = ReturnType<typeof fetchPortalAction>;

export interface AnalyticsMetaData {
    analyticsSuccessEvent?: UIAnalyticsEvent;
    analyticsFailureEvent?: UIAnalyticsEvent;
}

export const fetchPortalAction = (payload: ModelTypeOptions['portal'], meta?: AnalyticsMetaData) => ({
    meta,
    ...buildModelsActionCreator(FETCH_PORTAL_MODEL, 'portal')(payload),
});

export interface FetchPortalSuccess {
    type: typeof FETCH_PORTAL_SUCCESS;
    payload: PortalWithThemeResponse;
}

export const fetchPortalSuccess = (
    portalModel: PortalResponse,
    portalBrandingResponse: PortalBrandingResponseWithoutId
): FetchPortalSuccess => {
    if (removeOnlyPortalFromPortalModelAPIEnabled()) {
        return {
            payload: {
                ...portalBrandingResponse,
                ...portalModel,
                onlyPortal: undefined,
            },
            type: FETCH_PORTAL_SUCCESS,
        };
    }
    return {
        payload: {
            ...portalBrandingResponse,
            ...portalModel,
        },
        type: FETCH_PORTAL_SUCCESS,
    };
};

export interface FetchPortalFailure {
    type: typeof FETCH_PORTAL_FAILURE;
    payload: {
        portalId: number;
        error: ErrorResponse;
    };
}

export const fetchPortalFailure = (portalId: number, error: ErrorResponse): FetchPortalFailure => ({
    type: FETCH_PORTAL_FAILURE,
    payload: {
        portalId,
        error,
    },
});

export interface UpdatePortalAnnouncementModel {
    type: typeof UPDATE_PORTAL_ANNOUNCEMENT_MODEL;
    portalId: number;
    payload: PortalAnnouncementUpdatePayload;
    meta?: {
        analyticsSuccessEvent?: UIAnalyticsEvent;
        analyticsFailureEvent?: UIAnalyticsEvent;
        updateSinglePortalAnnouncementSideBarExperience: UFOExperience;
    };
}

export const updatePortalAnnouncementModel = (
    portalId: number,
    portalAnnouncementModel: PortalAnnouncementUpdatePayload,
    meta?: {
        analyticsSuccessEvent?: UIAnalyticsEvent;
        analyticsFailureEvent?: UIAnalyticsEvent;
        updateSinglePortalAnnouncementSideBarExperience: UFOExperience;
    }
): UpdatePortalAnnouncementModel => ({
    portalId,
    meta,
    type: UPDATE_PORTAL_ANNOUNCEMENT_MODEL,
    payload: portalAnnouncementModel,
});

export interface UpdatePortalAnnouncementSuccess {
    type: typeof UPDATE_PORTAL_ANNOUNCEMENT_SUCCESS;
    payload: PortalAnnouncementsUpdateResponse;
}

export const updatePortalAnnouncementSuccess = (
    portalAnnouncementModel: PortalAnnouncementsUpdateResponse
): UpdatePortalAnnouncementSuccess => ({
    type: UPDATE_PORTAL_ANNOUNCEMENT_SUCCESS,
    payload: portalAnnouncementModel,
});

export interface UpdatePortalAnnouncementFailure {
    type: typeof UPDATE_PORTAL_ANNOUNCEMENT_FAILURE;
    payload: {
        error: ErrorResponse;
    };
}

export const updatePortalAnnouncementFailure = (error: ErrorResponse): UpdatePortalAnnouncementFailure => ({
    type: UPDATE_PORTAL_ANNOUNCEMENT_FAILURE,
    payload: {
        error,
    },
});

export interface UpdatePortalAnnouncementsUIState {
    type: typeof UPDATE_PORTAL_ANNOUNCEMENTS_UI_STATE;
    payload: PortalAnnouncementUpdatePayload;
}

export const updatePortalAnnouncementsUIState = (
    portalAnnouncementModel: PortalAnnouncementUpdatePayload
): UpdatePortalAnnouncementsUIState => ({
    type: UPDATE_PORTAL_ANNOUNCEMENTS_UI_STATE,
    payload: portalAnnouncementModel,
});

export interface FetchPortalUploadMediaTokenAction {
    type: typeof FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_ACTION;
    payload: {
        portalId: number;
    };
}

export const fetchPortalUploadMediaTokenAction = (portalId: number): FetchPortalUploadMediaTokenAction => ({
    type: FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_ACTION,
    payload: {
        portalId,
    },
});

export interface FetchPortalUploadMediaTokenSuccess {
    type: typeof FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_SUCCESS;
    payload: {
        mediaApiUploadInformation: MediaApiUploadInformation;
        portalId: number;
    };
}

export const fetchPortalUploadMediaTokenSuccess = (
    mediaUploadToken: MediaApiUploadInformation,
    portalId: number
): FetchPortalUploadMediaTokenSuccess => {
    return {
        type: FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_SUCCESS,
        payload: {
            portalId,
            mediaApiUploadInformation: mediaUploadToken,
        },
    };
};

export interface FetchPortalUploadMediaTokenFailure {
    type: typeof FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_FAILURE;
}

export const fetchPortalUploadMediaTokenFailure = (): FetchPortalUploadMediaTokenFailure => {
    return {
        type: FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_FAILURE,
    };
};
