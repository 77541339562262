/**
 * @generated SignedSource<<69c27038c18c8a6eb0660688ee6a5e97>>
 * @relayHash 680d1ead84697fd3a6f00c86a8372631
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e3871268f30d1661ca8ffe170ad47de696c4974d2174ebb16111c28bcbac6a03

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type HelpLayoutFilter = {
  isEditMode?: boolean | null | undefined;
};
export type viewCustomPageQuery$variables = {
  cloudId: string;
  filter?: HelpLayoutFilter | null | undefined;
  helpCenterAri: string;
  helpCenterPageAri: string;
};
export type viewCustomPageQuery$data = {
  readonly helpCenter: {
    readonly helpCenterPageById: {
      readonly __typename: "QueryError";
      readonly description?: {
        readonly default: string;
      } | null | undefined;
      readonly name?: {
        readonly default: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly helpLayout: {
    readonly layoutByParentId: {
      readonly __typename: "QueryError";
      readonly extensions?: ReadonlyArray<{
        readonly statusCode: number | null | undefined;
      }> | null | undefined;
      readonly message?: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"layoutViewRendererFragment">;
    };
  } | null | undefined;
};
export type viewCustomPageQuery = {
  response: viewCustomPageQuery$data;
  variables: viewCustomPageQuery$variables;
};
({
  "__relay_internal__pv__relayprovidercustompagerelayprovider": isCustomPage_provider,
  "__relay_internal__pv__relayproviderhomepagerelayprovider": isHomePage_provider
} as {
  readonly __relay_internal__pv__relayprovidercustompagerelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__relayproviderhomepagerelayprovider: {
    readonly get: () => boolean;
  };
});

import isCustomPage_provider from './../../../../../../../../src/view/layout-primitives/subsection-view-renderer/relay-provider/custom-page.relayprovider';
import isHomePage_provider from './../../../../../../../../src/view/layout-primitives/subsection-view-renderer/relay-provider/home-page.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterPageAri"
},
v4 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "helpCenterPageAri",
    "variableName": "helpCenterPageAri"
  }
],
v6 = [
  {
    "kind": "ScalarField",
    "name": "default"
  }
],
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "HelpCenterPageName",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": (v6/*: any*/)
    },
    {
      "concreteType": "HelpCenterPageDescription",
      "kind": "LinkedField",
      "name": "description",
      "plural": false,
      "selections": (v6/*: any*/)
    }
  ],
  "type": "HelpCenterPage"
},
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v9 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "parentAri",
    "variableName": "helpCenterPageAri"
  }
],
v10 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v11 = {
  "kind": "ScalarField",
  "name": "message"
},
v12 = {
  "kind": "ScalarField",
  "name": "id"
},
v13 = {
  "kind": "InlineFragment",
  "selections": [
    (v12/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v14 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v15 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "adf"
    },
    {
      "concreteType": "HelpLayoutAtomicElementType",
      "kind": "LinkedField",
      "name": "elementType",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "parentAri",
              "variableName": "helpCenterAri"
            }
          ],
          "concreteType": "HelpLayoutMediaConfig",
          "kind": "LinkedField",
          "name": "mediaConfig",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "mediaCollectionName"
            },
            {
              "kind": "ScalarField",
              "name": "mediaToken"
            },
            {
              "kind": "ScalarField",
              "name": "mediaUrl"
            },
            {
              "kind": "ScalarField",
              "name": "asapIssuer"
            }
          ]
        }
      ]
    }
  ],
  "type": "HelpLayoutEditorElement"
},
v16 = {
  "kind": "ScalarField",
  "name": "name"
},
v17 = {
  "kind": "ScalarField",
  "name": "logo"
},
v18 = {
  "kind": "ScalarField",
  "name": "portalId"
},
v19 = {
  "concreteType": "HelpLayoutVisualConfig",
  "kind": "LinkedField",
  "name": "visualConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "titleColor"
    }
  ]
},
v20 = {
  "kind": "ScalarField",
  "name": "hidden"
},
v21 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v22 = {
  "kind": "ScalarField",
  "name": "backgroundColor"
},
v23 = {
  "kind": "ScalarField",
  "name": "backgroundType"
},
v24 = {
  "concreteType": "HelpLayoutBackgroundImage",
  "kind": "LinkedField",
  "name": "backgroundImage",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "url"
    }
  ]
},
v25 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "useGlobalSettings"
    },
    {
      "kind": "ScalarField",
      "name": "homePageTitle"
    },
    {
      "kind": "ScalarField",
      "name": "userLanguageTag"
    },
    {
      "concreteType": "HelpLayoutVisualConfig",
      "kind": "LinkedField",
      "name": "visualConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "foregroundColor"
        },
        (v22/*: any*/),
        (v23/*: any*/),
        (v24/*: any*/)
      ]
    }
  ],
  "type": "HelpLayoutHeroElement"
},
v26 = {
  "kind": "ScalarField",
  "name": "identifier"
},
v27 = {
  "kind": "LinkedField",
  "name": "extensions",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    (v10/*: any*/)
  ]
},
v28 = {
  "kind": "InlineFragment",
  "selections": [
    (v26/*: any*/)
  ],
  "type": "QueryError"
},
v29 = [
  {
    "concreteType": "HelpLayoutAtomicElementType",
    "kind": "LinkedField",
    "name": "elementType",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "key"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "viewCustomPageQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterPageById",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v8/*: any*/)
                ],
                "type": "QueryError"
              }
            ]
          }
        ]
      },
      {
        "args": (v4/*: any*/),
        "concreteType": "HelpLayoutQueryApi",
        "kind": "LinkedField",
        "name": "helpLayout",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "args": (v9/*: any*/),
              "kind": "LinkedField",
              "name": "layoutByParentId",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "layoutViewRendererFragment"
                    }
                  ],
                  "type": "HelpLayout"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "extensions",
                      "plural": true,
                      "selections": [
                        (v10/*: any*/)
                      ]
                    },
                    (v11/*: any*/),
                    (v8/*: any*/)
                  ],
                  "type": "QueryError"
                }
              ]
            },
            "action": "THROW",
            "path": "helpLayout.layoutByParentId"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__relayprovidercustompagerelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__relayproviderhomepagerelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "viewCustomPageQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterPageById",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v7/*: any*/),
              (v13/*: any*/)
            ]
          }
        ]
      },
      {
        "args": (v4/*: any*/),
        "concreteType": "HelpLayoutQueryApi",
        "kind": "LinkedField",
        "name": "helpLayout",
        "plural": false,
        "selections": [
          {
            "args": (v9/*: any*/),
            "kind": "LinkedField",
            "name": "layoutByParentId",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": (v14/*: any*/),
                    "concreteType": "HelpLayoutSectionConnection",
                    "kind": "LinkedField",
                    "name": "sections",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "concreteType": "HelpLayoutSectionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "HelpLayoutSection",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "HelpLayoutSubsection",
                                "kind": "LinkedField",
                                "name": "subsections",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "HelpLayoutSubsectionConfig",
                                    "kind": "LinkedField",
                                    "name": "config",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "span"
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "name": "elements",
                                    "plural": true,
                                    "selections": [
                                      (v8/*: any*/),
                                      {
                                        "kind": "TypeDiscriminator",
                                        "abstractKey": "__isHelpLayoutElement"
                                      },
                                      {
                                        "condition": "__relay_internal__pv__relayproviderhomepagerelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                          (v15/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "HelpLayoutPortalCard",
                                                "kind": "LinkedField",
                                                "name": "portals",
                                                "plural": true,
                                                "selections": [
                                                  (v16/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "description"
                                                  },
                                                  (v17/*: any*/),
                                                  (v18/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "isFeatured"
                                                  }
                                                ]
                                              }
                                            ],
                                            "type": "HelpLayoutPortalsListElement"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "HelpLayoutRequestForm",
                                                "kind": "LinkedField",
                                                "name": "suggestedRequestTypes",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "descriptionHtml"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "portalName"
                                                  },
                                                  (v12/*: any*/),
                                                  (v18/*: any*/),
                                                  (v16/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "iconUrl"
                                                  }
                                                ]
                                              },
                                              (v19/*: any*/)
                                            ],
                                            "type": "HelpLayoutSuggestedRequestFormsListElement"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "header"
                                              },
                                              (v11/*: any*/)
                                            ],
                                            "type": "HelpLayoutAnnouncementElement"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "HelpLayoutTopic",
                                                "kind": "LinkedField",
                                                "name": "topics",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "topicId"
                                                  },
                                                  (v20/*: any*/),
                                                  {
                                                    "concreteType": "HelpLayoutTopicItem",
                                                    "kind": "LinkedField",
                                                    "name": "items",
                                                    "plural": true,
                                                    "selections": [
                                                      (v8/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "title"
                                                      },
                                                      (v17/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "displayLink"
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "entityKey"
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "helpObjectType"
                                                      },
                                                      (v21/*: any*/)
                                                    ]
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "topicName"
                                                  }
                                                ]
                                              },
                                              (v19/*: any*/)
                                            ],
                                            "type": "HelpLayoutTopicsListElement"
                                          },
                                          (v25/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "forgeElementType"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "forgeElementPage"
                                              }
                                            ],
                                            "type": "HelpLayoutForgeElement"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "connectElementPage"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "connectElementType"
                                              }
                                            ],
                                            "type": "HelpLayoutConnectElement"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v26/*: any*/),
                                              (v27/*: any*/)
                                            ],
                                            "type": "QueryError"
                                          }
                                        ]
                                      },
                                      {
                                        "condition": "__relay_internal__pv__relayprovidercustompagerelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                          (v15/*: any*/),
                                          (v25/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "HelpLayoutBreadcrumb",
                                                "kind": "LinkedField",
                                                "name": "items",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "relativeUrl"
                                                  },
                                                  (v16/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "HelpLayoutBreadcrumbElement"
                                          },
                                          (v28/*: any*/)
                                        ]
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v29/*: any*/),
                                        "type": "HelpLayoutAnnouncementElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v29/*: any*/),
                                        "type": "HelpLayoutEditorElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v29/*: any*/),
                                        "type": "HelpLayoutHeroElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v29/*: any*/),
                                        "type": "HelpLayoutPortalsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v29/*: any*/),
                                        "type": "HelpLayoutTopicsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v29/*: any*/),
                                        "type": "HelpLayoutSuggestedRequestFormsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v29/*: any*/),
                                        "type": "HelpLayoutNoContentElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v29/*: any*/),
                                        "type": "HelpLayoutForgeElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v29/*: any*/),
                                        "type": "HelpLayoutConnectElement"
                                      },
                                      (v28/*: any*/),
                                      (v13/*: any*/),
                                      (v21/*: any*/)
                                    ]
                                  },
                                  (v12/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "concreteType": "HelpLayoutVisualConfig",
                                        "kind": "LinkedField",
                                        "name": "visualConfig",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutAlignmentSettings",
                                            "kind": "LinkedField",
                                            "name": "alignment",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "horizontalAlignment"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "verticalAlignment"
                                              },
                                              (v21/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    "type": "HelpLayoutVisualEntity",
                                    "abstractKey": "__isHelpLayoutVisualEntity"
                                  },
                                  (v21/*: any*/)
                                ]
                              },
                              {
                                "concreteType": "HelpLayoutVisualConfig",
                                "kind": "LinkedField",
                                "name": "visualConfig",
                                "plural": false,
                                "selections": [
                                  (v22/*: any*/),
                                  (v23/*: any*/),
                                  (v24/*: any*/),
                                  (v20/*: any*/)
                                ]
                              },
                              (v12/*: any*/),
                              (v8/*: any*/),
                              (v21/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "cursor"
                          }
                        ]
                      },
                      {
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "endCursor"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "hasNextPage"
                          }
                        ]
                      }
                    ],
                    "storageKey": "sections(first:10)"
                  },
                  {
                    "args": (v14/*: any*/),
                    "handle": "connection",
                    "key": "layoutRendererFragment_sections",
                    "kind": "LinkedHandle",
                    "name": "sections"
                  },
                  (v12/*: any*/)
                ],
                "type": "HelpLayout"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v27/*: any*/),
                  (v11/*: any*/)
                ],
                "type": "QueryError"
              },
              (v13/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e3871268f30d1661ca8ffe170ad47de696c4974d2174ebb16111c28bcbac6a03",
    "metadata": {},
    "name": "viewCustomPageQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__relayprovidercustompagerelayprovider": isCustomPage_provider,
      "__relay_internal__pv__relayproviderhomepagerelayprovider": isHomePage_provider
    }
  }
};
})();

(node as any).hash = "6356e9745baafcc9c0cced4e31e7349a";

export default node;
