import React, { useMemo } from 'react';
import { isUiModificationsSupportEnabled } from '@helpCenter/feature-flags';
import { UI_MODIFICATIONS_JSM_REQUEST_CREATE_RESOURCE } from '@helpCenter/routes/portal-home/resources';
import ErrorBoundary from '@helpCenter/view/error-boundary/error-boundary';
import { di } from 'react-magnetic-di';
import { getAnalyticsWebClientPromise } from '@atlassian/help-center-common-util/analytics/client';
import { getActivationId, getCloudId } from '@atlassian/help-center-common-util/meta/meta';
import { useRelayResource } from '@atlassian/help-center-common-util/resource-router';
import { AnalyticsListener } from '@atlassian/ui-modifications-analytics';
import { UiModificationsRoot } from '@atlassian/ui-modifications-core';
import { ForgeAppRenderer } from './forge-app-renderer';
import type { UiModificationsEntryPointProps } from './types';

// TODO: add memo to the below component
const EntryPoint = ({ portalId, requestTypeId }: UiModificationsEntryPointProps) => {
    di(UiModificationsRoot, useRelayResource, getActivationId, getCloudId, ForgeAppRenderer);

    const executionContextKey = portalId && requestTypeId ? `${portalId}-${requestTypeId}` : null;
    const cloudId = getCloudId();
    const activationId = getActivationId();

    const { queryReference } = useRelayResource(UI_MODIFICATIONS_JSM_REQUEST_CREATE_RESOURCE);

    const executionContext = useMemo(() => {
        if (!portalId || !requestTypeId) {
            return null;
        }

        return {
            portal: { id: portalId },
            requestType: { id: requestTypeId },
        };
    }, [portalId, requestTypeId]);

    return (
        <UiModificationsRoot
            executionContext={executionContext}
            queryReference={queryReference}
            cloudId={cloudId}
            activationId={activationId}
            executionContextKey={executionContextKey}
            triggerPointKey="DEFAULT"
            ForgeAppRenderer={ForgeAppRenderer}
        />
    );
};

export const UiModificationsEntryPoint = (props: UiModificationsEntryPointProps) => {
    di(AnalyticsListener, isUiModificationsSupportEnabled, ErrorBoundary, getAnalyticsWebClientPromise);

    return isUiModificationsSupportEnabled() ? (
        <ErrorBoundary id="uiModifications" packageName="jsmUiModificationsViewRequestCreate">
            <AnalyticsListener
                analyticsWebClient={getAnalyticsWebClientPromise().then((client) => client.getInstance())}
            >
                <EntryPoint {...props} />
            </AnalyticsListener>
        </ErrorBoundary>
    ) : null;
};
