import { NotFoundPage } from 'view/app/routes';
import { RouteName, RoutePath, ScreenName } from '@atlassian/help-center-common-component/constants';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';
import { ViewCustomPage } from '@atlassian/help-center-custom-pages';
import { ViewCustomPageResource } from './resources/view-custom-page/resources';

export const getViewCustomPageRoute = createRouteWithLayout(
    {
        name: RouteName.VIEW_CUSTOM_PAGE,
        path: RoutePath.VIEW_CUSTOM_PAGE,
        exact: true,
        component: ViewCustomPage,
        resources: [ViewCustomPageResource],
    },
    ScreenName.VIEW_CUSTOM_PAGE,
    {
        paperAppearance: 'none',
        fadeInSearchModal: true,
        hideSearchButton: true,
        hideBanner: true,
        removeHeader: true,
        prefetchBrandingModel: true,
    }
);

export const getEditCustomPageRoute = createRouteWithLayout(
    {
        name: RouteName.EDIT_CUSTOM_PAGE,
        path: '/page/edit/:id',
        exact: true,
        component: NotFoundPage,
    },
    ScreenName.EDIT_CUSTOM_PAGE,
    {
        paperAppearance: 'none',
        hideSearchButton: false,
        fadeInSearchModal: true,
        hideBanner: true,
        removeHeader: true,
    }
);
