import { useCallback } from 'react';
import { isOmnichannelGreetingAPIEnabled } from 'feature-flags';
import { graphql, useMutation, useRelayEnvironment } from 'react-relay';
import type { PayloadError } from 'relay-runtime';
import { generateUniqueClientID } from 'relay-runtime';
import { doc, p } from '@atlaskit/adf-utils/builders';
import { addMessageToConnection } from '@atlassian/omnichannel/src/controllers/add-message-to-connection';
import { updateOmniChannelConversationMeta } from '@atlassian/omnichannel/src/controllers/omnichannel-update-conversation-meta';
import {
    addGreetingMessageToConnection,
    addOptimisticResponseToConnection,
    clearTimer,
    deleteNodeFromConnection,
    resetTimer,
    updateMessageStatusToHelpseeker,
    updateRelayRecordId,
} from '@atlassian/omnichannel/src/controllers/store-actions';
import type {
    createConversationWithMessageMutation,
    createConversationWithMessageMutation$data,
} from './__generated__/createConversationWithMessageMutation.graphql';

interface CreateConversationWithMessageArgs {
    workspaceAri: string;
    conversationContextAri: string;
    onCompleted?: (response: createConversationWithMessageMutation$data, errors: PayloadError[] | null) => void;
    onError?: (error: Error) => void;
    isTestConversation?: boolean;
    message: string;
    subscriptionId: string;
    connectionId: string;
}

interface HandleMutationErrorArgs {
    nodeId: string;
    message: string;
    connectionId: string;
}

export const useCreateConversationWithMessage = () => {
    const [commitMutation, isLoading] = useMutation<createConversationWithMessageMutation>(graphql`
        mutation createConversationWithMessageMutation(
            $workspaceAri: ID!
            $conversationContextAri: ID!
            $isTestChannel: Boolean = false
            $message: String!
            $subscriptionId: String!
        ) {
            jsmChat {
                initializeAndSendMessage(
                    workspaceAri: $workspaceAri
                    input: {
                        conversationContextAri: $conversationContextAri
                        isTestChannel: $isTestChannel
                        channelExperienceId: HELPCENTER
                        message: $message
                        subscriptionId: $subscriptionId
                    }
                ) @optIn(to: "JsmChatVAConversationAPI") {
                    success
                    errors {
                        message
                        extensions {
                            statusCode
                        }
                    }
                    initializeAndSendMessageResponse {
                        conversationId
                        conversation {
                            node {
                                id
                                content
                                authorType
                                contentType
                            }
                        }
                    }
                }
            }
        }
    `);

    const environment = useRelayEnvironment();

    const handleMutationError = useCallback(
        ({ nodeId, message, connectionId }: HandleMutationErrorArgs) => {
            updateOmniChannelConversationMeta({ environment, isReplyPending: false });
            deleteNodeFromConnection({ environment, connectionId, nodeId });
            addMessageToConnection({
                message,
                environment,
                connectionId,
                authorType: 'CombinedAPIError',
            });
        },
        [environment]
    );

    const createConversationWithMessage = useCallback(
        ({
            workspaceAri,
            conversationContextAri,
            connectionId,
            onError,
            message,
            subscriptionId,
            isTestConversation = false,
        }: CreateConversationWithMessageArgs) => {
            const nodeId = generateUniqueClientID();
            const content = JSON.stringify(doc(p(message)));
            addOptimisticResponseToConnection({
                environment,
                nodeId,
                content,
                connectionId,
                message,
            });
            updateOmniChannelConversationMeta({ environment, isReplyPending: true });
            updateMessageStatusToHelpseeker({ environment });
            resetTimer({ environment });

            if (isOmnichannelGreetingAPIEnabled()) {
                addGreetingMessageToConnection({
                    environment,
                    connectionId,
                    nodeId: generateUniqueClientID(),
                });
            }

            return commitMutation({
                onCompleted: (response) => {
                    if (
                        !response?.jsmChat?.initializeAndSendMessage?.success ||
                        !response?.jsmChat?.initializeAndSendMessage?.initializeAndSendMessageResponse?.conversationId
                    ) {
                        handleMutationError({
                            nodeId,
                            message,
                            connectionId,
                        });
                        return;
                    }
                    const responseData = response.jsmChat?.initializeAndSendMessage?.initializeAndSendMessageResponse;
                    updateOmniChannelConversationMeta({
                        environment,
                        workspaceAri,
                        conversationContextAri,
                        conversationId: responseData?.conversationId,
                    });
                    clearTimer(environment);
                    if (responseData.conversationId && responseData.conversation?.node?.id) {
                        updateRelayRecordId({
                            environment,
                            nodeId,
                            newId: responseData.conversation?.node?.id as string,
                        });
                    }
                },
                onError: (error) => {
                    handleMutationError({
                        nodeId,
                        message,
                        connectionId,
                    });
                    onError?.(error);
                },
                variables: {
                    workspaceAri,
                    conversationContextAri,
                    subscriptionId,
                    isTestChannel: isTestConversation,
                    message: content,
                },
            });
        },
        [commitMutation, environment, handleMutationError]
    );

    return {
        isLoading,
        createConversationWithMessage,
    };
};
