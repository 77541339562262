/**
 * @generated SignedSource<<2120fcf0963c46b511dd55374d413bf9>>
 * @relayHash c29c401acd522a80ee9bfde6c47dc16c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0f40f1c0b6434052889d9f4030e7eb18874bd5bbad26d4ea144ce065e0fca4da

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type HelpLayoutFilter = {
  isEditMode?: boolean | null | undefined;
};
export type viewHomepageQuery$variables = {
  cloudId: string;
  filter?: HelpLayoutFilter | null | undefined;
  helpCenterAri: string;
  layoutAri: string;
};
export type viewHomepageQuery$data = {
  readonly helpCenter: {
    readonly helpCenterById: {
      readonly __typename: "QueryError";
      readonly announcements?: {
        readonly canEditHomePageAnnouncement: boolean | null | undefined;
      } | null | undefined;
      readonly name?: {
        readonly default: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly helpLayout: {
    readonly layout: {
      readonly __typename: "QueryError";
      readonly extensions?: ReadonlyArray<{
        readonly statusCode: number | null | undefined;
      }> | null | undefined;
      readonly message?: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"layoutViewRendererFragment">;
    };
  } | null | undefined;
};
export type viewHomepageQuery = {
  response: viewHomepageQuery$data;
  variables: viewHomepageQuery$variables;
};
({
  "__relay_internal__pv__relayprovidercustompagerelayprovider": isCustomPage_provider,
  "__relay_internal__pv__relayproviderhomepagerelayprovider": isHomePage_provider
} as {
  readonly __relay_internal__pv__relayprovidercustompagerelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__relayproviderhomepagerelayprovider: {
    readonly get: () => boolean;
  };
});

import isCustomPage_provider from './../../../layout-primitives/subsection-view-renderer/relay-provider/custom-page.relayprovider';
import isHomePage_provider from './../../../layout-primitives/subsection-view-renderer/relay-provider/home-page.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "layoutAri"
},
v4 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "HelpCenterName",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "default"
        }
      ]
    },
    {
      "concreteType": "HelpCenterAnnouncements",
      "kind": "LinkedField",
      "name": "announcements",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "canEditHomePageAnnouncement"
        }
      ]
    }
  ],
  "type": "HelpCenter"
},
v7 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v8 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "layoutAri"
  }
],
v9 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v10 = {
  "kind": "ScalarField",
  "name": "message"
},
v11 = {
  "kind": "ScalarField",
  "name": "id"
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    (v11/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v14 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "adf"
    },
    {
      "concreteType": "HelpLayoutAtomicElementType",
      "kind": "LinkedField",
      "name": "elementType",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "parentAri",
              "variableName": "helpCenterAri"
            }
          ],
          "concreteType": "HelpLayoutMediaConfig",
          "kind": "LinkedField",
          "name": "mediaConfig",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "mediaCollectionName"
            },
            {
              "kind": "ScalarField",
              "name": "mediaToken"
            },
            {
              "kind": "ScalarField",
              "name": "mediaUrl"
            },
            {
              "kind": "ScalarField",
              "name": "asapIssuer"
            }
          ]
        }
      ]
    }
  ],
  "type": "HelpLayoutEditorElement"
},
v15 = {
  "kind": "ScalarField",
  "name": "name"
},
v16 = {
  "kind": "ScalarField",
  "name": "logo"
},
v17 = {
  "kind": "ScalarField",
  "name": "portalId"
},
v18 = {
  "concreteType": "HelpLayoutVisualConfig",
  "kind": "LinkedField",
  "name": "visualConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "titleColor"
    }
  ]
},
v19 = {
  "kind": "ScalarField",
  "name": "hidden"
},
v20 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v21 = {
  "kind": "ScalarField",
  "name": "backgroundColor"
},
v22 = {
  "kind": "ScalarField",
  "name": "backgroundType"
},
v23 = {
  "concreteType": "HelpLayoutBackgroundImage",
  "kind": "LinkedField",
  "name": "backgroundImage",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "url"
    }
  ]
},
v24 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "useGlobalSettings"
    },
    {
      "kind": "ScalarField",
      "name": "homePageTitle"
    },
    {
      "kind": "ScalarField",
      "name": "userLanguageTag"
    },
    {
      "concreteType": "HelpLayoutVisualConfig",
      "kind": "LinkedField",
      "name": "visualConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "foregroundColor"
        },
        (v21/*: any*/),
        (v22/*: any*/),
        (v23/*: any*/)
      ]
    }
  ],
  "type": "HelpLayoutHeroElement"
},
v25 = {
  "kind": "ScalarField",
  "name": "identifier"
},
v26 = {
  "kind": "LinkedField",
  "name": "extensions",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    (v9/*: any*/)
  ]
},
v27 = {
  "kind": "InlineFragment",
  "selections": [
    (v25/*: any*/)
  ],
  "type": "QueryError"
},
v28 = [
  {
    "concreteType": "HelpLayoutAtomicElementType",
    "kind": "LinkedField",
    "name": "elementType",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "key"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "viewHomepageQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v7/*: any*/)
                ],
                "type": "QueryError"
              }
            ]
          }
        ]
      },
      {
        "args": (v4/*: any*/),
        "concreteType": "HelpLayoutQueryApi",
        "kind": "LinkedField",
        "name": "helpLayout",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "args": (v8/*: any*/),
              "kind": "LinkedField",
              "name": "layout",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "layoutViewRendererFragment"
                    }
                  ],
                  "type": "HelpLayout"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "extensions",
                      "plural": true,
                      "selections": [
                        (v9/*: any*/)
                      ]
                    },
                    (v10/*: any*/),
                    (v7/*: any*/)
                  ],
                  "type": "QueryError"
                }
              ]
            },
            "action": "THROW",
            "path": "helpLayout.layout"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__relayprovidercustompagerelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__relayproviderhomepagerelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "viewHomepageQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v6/*: any*/),
              (v12/*: any*/)
            ]
          }
        ]
      },
      {
        "args": (v4/*: any*/),
        "concreteType": "HelpLayoutQueryApi",
        "kind": "LinkedField",
        "name": "helpLayout",
        "plural": false,
        "selections": [
          {
            "args": (v8/*: any*/),
            "kind": "LinkedField",
            "name": "layout",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": (v13/*: any*/),
                    "concreteType": "HelpLayoutSectionConnection",
                    "kind": "LinkedField",
                    "name": "sections",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "concreteType": "HelpLayoutSectionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "HelpLayoutSection",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "HelpLayoutSubsection",
                                "kind": "LinkedField",
                                "name": "subsections",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "HelpLayoutSubsectionConfig",
                                    "kind": "LinkedField",
                                    "name": "config",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "span"
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "name": "elements",
                                    "plural": true,
                                    "selections": [
                                      (v7/*: any*/),
                                      {
                                        "kind": "TypeDiscriminator",
                                        "abstractKey": "__isHelpLayoutElement"
                                      },
                                      {
                                        "condition": "__relay_internal__pv__relayproviderhomepagerelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                          (v14/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "HelpLayoutPortalCard",
                                                "kind": "LinkedField",
                                                "name": "portals",
                                                "plural": true,
                                                "selections": [
                                                  (v15/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "description"
                                                  },
                                                  (v16/*: any*/),
                                                  (v17/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "isFeatured"
                                                  }
                                                ]
                                              }
                                            ],
                                            "type": "HelpLayoutPortalsListElement"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "HelpLayoutRequestForm",
                                                "kind": "LinkedField",
                                                "name": "suggestedRequestTypes",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "descriptionHtml"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "portalName"
                                                  },
                                                  (v11/*: any*/),
                                                  (v17/*: any*/),
                                                  (v15/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "iconUrl"
                                                  }
                                                ]
                                              },
                                              (v18/*: any*/)
                                            ],
                                            "type": "HelpLayoutSuggestedRequestFormsListElement"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "header"
                                              },
                                              (v10/*: any*/)
                                            ],
                                            "type": "HelpLayoutAnnouncementElement"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "HelpLayoutTopic",
                                                "kind": "LinkedField",
                                                "name": "topics",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "topicId"
                                                  },
                                                  (v19/*: any*/),
                                                  {
                                                    "concreteType": "HelpLayoutTopicItem",
                                                    "kind": "LinkedField",
                                                    "name": "items",
                                                    "plural": true,
                                                    "selections": [
                                                      (v7/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "title"
                                                      },
                                                      (v16/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "displayLink"
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "entityKey"
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "helpObjectType"
                                                      },
                                                      (v20/*: any*/)
                                                    ]
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "topicName"
                                                  }
                                                ]
                                              },
                                              (v18/*: any*/)
                                            ],
                                            "type": "HelpLayoutTopicsListElement"
                                          },
                                          (v24/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "forgeElementType"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "forgeElementPage"
                                              }
                                            ],
                                            "type": "HelpLayoutForgeElement"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "connectElementPage"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "connectElementType"
                                              }
                                            ],
                                            "type": "HelpLayoutConnectElement"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v25/*: any*/),
                                              (v26/*: any*/)
                                            ],
                                            "type": "QueryError"
                                          }
                                        ]
                                      },
                                      {
                                        "condition": "__relay_internal__pv__relayprovidercustompagerelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                          (v14/*: any*/),
                                          (v24/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "HelpLayoutBreadcrumb",
                                                "kind": "LinkedField",
                                                "name": "items",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "relativeUrl"
                                                  },
                                                  (v15/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "HelpLayoutBreadcrumbElement"
                                          },
                                          (v27/*: any*/)
                                        ]
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v28/*: any*/),
                                        "type": "HelpLayoutAnnouncementElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v28/*: any*/),
                                        "type": "HelpLayoutEditorElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v28/*: any*/),
                                        "type": "HelpLayoutHeroElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v28/*: any*/),
                                        "type": "HelpLayoutPortalsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v28/*: any*/),
                                        "type": "HelpLayoutTopicsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v28/*: any*/),
                                        "type": "HelpLayoutSuggestedRequestFormsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v28/*: any*/),
                                        "type": "HelpLayoutNoContentElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v28/*: any*/),
                                        "type": "HelpLayoutForgeElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v28/*: any*/),
                                        "type": "HelpLayoutConnectElement"
                                      },
                                      (v27/*: any*/),
                                      (v12/*: any*/),
                                      (v20/*: any*/)
                                    ]
                                  },
                                  (v11/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "concreteType": "HelpLayoutVisualConfig",
                                        "kind": "LinkedField",
                                        "name": "visualConfig",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutAlignmentSettings",
                                            "kind": "LinkedField",
                                            "name": "alignment",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "horizontalAlignment"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "verticalAlignment"
                                              },
                                              (v20/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    "type": "HelpLayoutVisualEntity",
                                    "abstractKey": "__isHelpLayoutVisualEntity"
                                  },
                                  (v20/*: any*/)
                                ]
                              },
                              {
                                "concreteType": "HelpLayoutVisualConfig",
                                "kind": "LinkedField",
                                "name": "visualConfig",
                                "plural": false,
                                "selections": [
                                  (v21/*: any*/),
                                  (v22/*: any*/),
                                  (v23/*: any*/),
                                  (v19/*: any*/)
                                ]
                              },
                              (v11/*: any*/),
                              (v7/*: any*/),
                              (v20/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "cursor"
                          }
                        ]
                      },
                      {
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "endCursor"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "hasNextPage"
                          }
                        ]
                      }
                    ],
                    "storageKey": "sections(first:10)"
                  },
                  {
                    "args": (v13/*: any*/),
                    "handle": "connection",
                    "key": "layoutRendererFragment_sections",
                    "kind": "LinkedHandle",
                    "name": "sections"
                  },
                  (v11/*: any*/)
                ],
                "type": "HelpLayout"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v26/*: any*/),
                  (v10/*: any*/)
                ],
                "type": "QueryError"
              },
              (v12/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "0f40f1c0b6434052889d9f4030e7eb18874bd5bbad26d4ea144ce065e0fca4da",
    "metadata": {},
    "name": "viewHomepageQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__relayprovidercustompagerelayprovider": isCustomPage_provider,
      "__relay_internal__pv__relayproviderhomepagerelayprovider": isHomePage_provider
    }
  }
};
})();

(node as any).hash = "2bcab35a024f85aecee6a272f8d5db60";

export default node;
