import { defineMessages } from 'react-intl-next';

export default defineMessages({
    emptyConfigPanel: {
        defaultMessage: 'Add rich text content or select a section to modify',
        description: 'Text to be displayed when no section is selected in the layout builder',
        id: 'cv.portal.customization.config.panel.empty.non-final',
    },
    emptyConfigPanelImageAltText: {
        defaultMessage: 'The config panel is empty',
        description: 'Alt text for image to be displayed when no section is selected in the layout builder',
        id: 'cv.portal.customization.config.panel.empty.image.alt.non-final',
    },
});
