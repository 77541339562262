import React from 'react';
import emptySearchQuery from 'assets/images/empty-search-query.svg';
import emptySearchResults from 'assets/images/empty-search-results.svg';
import magnifyingGlass from 'assets/images/search-modal-magnifying-glass.svg';
import { isSearchUIRedesignEnabled, isSearchUIRedesignContentEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import Heading from '@atlaskit/heading';
import { Stack, Text } from '@atlaskit/primitives';
import { ExperienceSuccess } from '@atlassian/ufo';
import { Img } from '../common';
import { searchExperience } from '../experiences';
import messages from './messages';

export interface Props {
    term: string;
}

export const SearchResultNone = ({ term }: Props) => {
    di(isSearchUIRedesignEnabled, isSearchUIRedesignContentEnabled);

    const { formatMessage } = useIntl();

    if (isSearchUIRedesignEnabled()) {
        if (isSearchUIRedesignContentEnabled() && term === '') {
            return (
                <Stack space="space.100" alignInline="center">
                    <Img src={emptySearchQuery} alt="" />
                    <Heading as="h2" level="h400">
                        {formatMessage(messages.noSearchQueryTitle)}
                    </Heading>
                    <Text align="center">{formatMessage(messages.noSearchQueryBody)}</Text>
                    <ExperienceSuccess experience={searchExperience} />
                </Stack>
            );
        }

        return (
            <Stack space="space.100" alignInline="center">
                <Img src={emptySearchResults} alt="" />
                <Heading as="h2" level="h400">
                    {formatMessage(messages.noSearchResultTitle)}
                </Heading>
                <Text align="center">
                    {isSearchUIRedesignContentEnabled()
                        ? formatMessage(messages.noSearchResultBody)
                        : formatMessage(messages.noSearchResultText, { term })}
                </Text>
                <ExperienceSuccess experience={searchExperience} />
            </Stack>
        );
    }

    return (
        <Stack alignInline="center">
            <Img src={magnifyingGlass} alt="" />
            <Heading as="h2" level="h400">
                {formatMessage(messages.noSearchResultTitle)}
            </Heading>
            {/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve */}
            <p style={{ textAlign: 'center' }}>{formatMessage(messages.noSearchResultText, { term })}</p>
        </Stack>
    );
};
