export const isCustomPage = (): boolean => {
    let path = window.location.pathname;
    if (path.endsWith('/')) {
        path = path.slice(0, -1);
    }
    const customPagePattern = /\/page\/[^/]+(\/.+)?$/;
    return customPagePattern.test(path);
};

export const isHomePage = (): boolean => {
    let path = window.location.pathname;
    if (path.endsWith('/')) {
        path = path.slice(0, -1);
    }
    const homepagePatterns = [/\/servicedesk\/customer\/portals$/, /\/helpcenter\/[^/]+$/];
    return homepagePatterns.some((pattern) => pattern.test(path));
};
