import type { ImmutableState } from '../types';

export const getIsPageInEditMode = (state: ImmutableState) => state.isPageInEditMode;

export const getIsPageInPreviewMode = (state: ImmutableState) => state.isPageInPreviewMode;

export const getSectionPickerId = (state: ImmutableState) => state.events.sectionPickerId;

export const getSelectedPrimitive = (state: ImmutableState) => state.events.selected;

export const getIsPublishing = (state: ImmutableState) => state.isPublishing;

export const getIsPublishDisabled = (state: ImmutableState) => state.isPublishDisabled;

export const getIsDirty = (state: ImmutableState) => state.isDirty;

export const getMediaConfig = (state: ImmutableState) => state.mediaConfig;

export const getIsPageInBrandingPreviewMode = (state: ImmutableState) => state.isPageInBrandingPreviewMode;

export const getLayoutConfig = (state: ImmutableState) => state.config;

export const getLayoutIds = (state: ImmutableState) => state.layoutIds;

export const getElementRenderer = (state: ImmutableState) => state.ElementRenderer;

export const getConfigPanelUI = (state: ImmutableState) => state.configPanel.ui;
