import * as React from 'react';
import { PaperContent } from 'view/layout/paper';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { SkeletonLoadingWrapper } from '@atlassian/help-center-common-component/loading';
import type { LoadingContainerProps } from '@atlassian/help-center-common-component/loading-container';

/**
 * Displays children when isLoading is false, otherwise shows loading spinner.
 */
export class SkeletonLoadingContainer extends React.Component<LoadingContainerProps> {
    static defaultProps = {
        loadingPosition: 'fill-container',
    };

    render() {
        const { children, loadingPosition, isLoading, enableUFOHold, ufoHoldName } = this.props;

        const skeleton = <SkeletonLoadingWrapper isLoading loadingPosition={loadingPosition} />;

        return isLoading ? (
            <PaperContent>
                {enableUFOHold ? <UFOLoadHold name={ufoHoldName}>{skeleton}</UFOLoadHold> : skeleton}
            </PaperContent>
        ) : (
            children
        );
    }
}
