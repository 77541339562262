import type { EnrichedRequestCreateResponse } from 'rest/request-create';
import type {
    FetchRequestCreateAction,
    FetchRequestCreateSuccess,
    FetchRequestCreateFailure,
    ResetRequestCreateStateAction,
    ProformaFormDirty,
} from 'state/actions/request-create';
import {
    FETCH_REQUEST_CREATE_MODEL,
    FETCH_REQUEST_CREATE_SUCCESS,
    FETCH_REQUEST_CREATE_FAILURE,
    PROFORMA_FORM_DIRTY,
    RESET_REQUEST_CREATE_STATE,
} from 'state/actions/request-create';
import { contextPath, getBaseName } from '@atlassian/help-center-common-util/history';
import { initialModel } from '@atlassian/help-center-common-util/model';
import { toNumber } from '@atlassian/help-center-common-util/number';
import { toFieldStates } from '@atlassian/help-center-common-util/request-create';
import { parseFieldUrlParams } from '@atlassian/help-center-common-util/request-create/url-param-parser';
import { convertToDateFnsFormat } from '@atlassian/help-center-common-util/unix-time-parser';
import type { RequestCreateState, RequestCreateKBState, RequestCreateFormState, FieldState, Form } from './types';

type Actions =
    | FetchRequestCreateFailure
    | FetchRequestCreateSuccess
    | FetchRequestCreateAction
    | ProformaFormDirty
    | ResetRequestCreateStateAction;

const buildStoreKey = (portalId: number | undefined, requestCreateId: number) =>
    `${portalId || 'undefined'}/${requestCreateId}`;

function reduceReqCreateResponseToState(reqCreateResponse: EnrichedRequestCreateResponse) {
    const { portalId, id: requestCreateId } = reqCreateResponse;
    const storeId = buildStoreKey(portalId, requestCreateId);
    const {
        showRecaptcha,
        siteKey,
        proformaTemplateForm,
        form: { name, intro, instructions, descriptionHtml, icon, iconUrl },
    } = reqCreateResponse;
    const form: Form = {
        name,
        intro,
        instructions,
        descriptionHtml,
        iconUrl:
            iconUrl ||
            `${contextPath}/servicedesk/customershim/secure/viewavatar?avatarType=SD_REQTYPE&avatarId=${icon}`,
        captchaEnabled: showRecaptcha,
        sitekey: siteKey,
    };

    const kbState =
        reqCreateResponse.kbs && reqCreateResponse.kbs.kbEnabled
            ? {
                  knowledgeBase: {
                      spaceKey: reqCreateResponse.kbs.kbLink.spaceKey,
                      labels: reqCreateResponse.kbs.labels,
                      projectKey: reqCreateResponse.kbs.projectKey,
                  } as RequestCreateKBState,
              }
            : {};

    const dateFormat = convertToDateFnsFormat(reqCreateResponse.calendarParams.dateIfFormat);
    const dateTimeFormat = convertToDateFnsFormat(reqCreateResponse.calendarParams.dateTimeIfFormat);
    const firstDay = reqCreateResponse.calendarParams.firstDay;
    const useISO8601WeekNumbers = reqCreateResponse.calendarParams.useISO8601WeekNumbers;

    const fields = toFieldStates(reqCreateResponse.fields);
    let initialFieldValues = fields.reduce((values, field: FieldState) => {
        // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        values[field.id] = field.value;
        return values;
    }, {});

    const { lastSavedFormData } = reqCreateResponse;

    if (
        lastSavedFormData &&
        lastSavedFormData.portalId === portalId &&
        lastSavedFormData.requestId === requestCreateId
    ) {
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        initialFieldValues = { ...initialFieldValues, ...lastSavedFormData.formInput };
    }

    const urlParamFieldValueOverrides = parseFieldUrlParams(fields);
    if (Object.keys(urlParamFieldValueOverrides).length > 0) {
        initialFieldValues = { ...initialFieldValues, ...urlParamFieldValueOverrides };
    }

    const organisationsForCurrentUser = reqCreateResponse.userOrganisations;

    const requestCreateFormState: RequestCreateFormState = {
        form,
        fields,
        initialFieldValues,
        urlParamFieldValueOverrides,
        dateFormat,
        dateTimeFormat,
        organisationsForCurrentUser,
        firstDay,
        useISO8601WeekNumbers,
        proformaTemplateForm,
        id: reqCreateResponse.id,
        projectId: reqCreateResponse.projectId,
        canBrowseUsers: !!reqCreateResponse.canBrowseUsers,
        canCreateUsers: !!reqCreateResponse.canSubmitWithEmailAddress,
        projectHasProformaForms: !!reqCreateResponse.hasProformaForm,
        proforma: reqCreateResponse.proforma,
        proformaFormIsLoaded: false,
        proformaFormIsDirty: false,
    };

    return {
        [storeId]: { ...requestCreateFormState, ...kbState, initialFieldValues },
    };
}

export const getInitialReqCreateState = () => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.reqCreate) {
        const portalId = toNumber(initialModelState.portal && initialModelState.portal.id);
        return reduceReqCreateResponseToState({ portalId, ...initialModelState.reqCreate });
    }
    return {};
};

const defaultState: RequestCreateState = getInitialReqCreateState();

export default function reducer(state: RequestCreateState = defaultState, action: Actions): RequestCreateState {
    switch (action.type) {
        case FETCH_REQUEST_CREATE_MODEL: {
            const requestCreateId = action.payload.params && action.payload.params.id;
            const portalId = action.payload.params && action.payload.params.portalId;

            if (requestCreateId && portalId) {
                const storeId = buildStoreKey(portalId, requestCreateId);
                const requestCreate = state[storeId];

                if (requestCreate && 'error' in requestCreate) {
                    const newState = {
                        ...state,
                    };

                    delete newState[storeId];

                    return newState;
                }
            }

            return state;
        }

        case FETCH_REQUEST_CREATE_FAILURE: {
            const { error, portalId, requestCreateId } = action.payload;
            const storeId = buildStoreKey(portalId, requestCreateId);

            return {
                ...state,
                [storeId]: {
                    error: {
                        // We really only care about the first error message
                        // grab it and throw the rest away
                        status: error.status,
                        message: (error.errorMessages || [])[0],
                        traceId: error.traceId,
                        responseHeaders: error.responseHeaders,
                        // Remove basename from the URL, we don't need it as the basename
                        // is already set inside react router.
                        // See ticket to update backend: https://jdog.jira-dev.com/browse/FSD-2557
                        callToActionUrl: (error.nextActionUrl || '').replace(getBaseName(), ''),
                        callToActionText: error.nextActionDisplayText || '',
                    },
                },
            };
        }

        case FETCH_REQUEST_CREATE_SUCCESS: {
            return {
                ...state,
                ...reduceReqCreateResponseToState(action.payload),
            };
        }

        case PROFORMA_FORM_DIRTY: {
            const { portalId, requestTypeId, isDirty } = action.payload;
            const storeId = buildStoreKey(portalId, requestTypeId);
            return {
                ...state,
                [storeId]: {
                    ...(state[storeId] as RequestCreateFormState),
                    proformaFormIsDirty: isDirty,
                },
            };
        }

        case RESET_REQUEST_CREATE_STATE: {
            // Reset the state to the default initial state
            return getInitialReqCreateState();
        }

        default:
            return state;
    }
}
