import { isHomePage } from '@helpCenter/util/custom-pages';
import { isElementRendererSplitEnabled } from 'feature-flags';

export default {
    get(): boolean {
        if (isElementRendererSplitEnabled()) {
            return isHomePage();
        }
        return true;
    },
};
