import clientServerBridge from '@atlassian/help-center-common-util/client-server-bridge/client-server-bridge';
import type { EnvType } from '../env';
export interface MetaData {
    accountId: string;
    userLocale: string;
    accountType: string;
    sdPluginVersion: string;
    contextPath: string;
    environment: EnvType;
    tenantId: string;
    activationId: string;
    helpCenterARI: string;
}

const requiredMetaTags = {
    'account-id': 'accountId',
    'ajs-user-locale': 'userLocale',
    'ajs-account-type': 'accountType',
    'ajs-sdPluginVersion': 'sdPluginVersion',
    'ajs-context-path': 'contextPath',
    environment: 'environment',
    'tenant-id': 'tenantId',
    'activation-id': 'activationId',
    'help-center-ari': 'helpCenterARI',
    'home-page-layout-ari': 'homePageLayoutARI',
};

export const getAllMetaData = (): MetaData => {
    const metaData = {} as MetaData;
    Object.entries(requiredMetaTags).forEach(([key, name]) => {
        // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        metaData[name] = getMeta(key);
    });

    return Object.freeze(metaData);
};

export function getMetaFromHTML(name: string): string | null {
    const metaElement = clientServerBridge.querySelector(`meta[name="${name}"]`);
    const metaValue = metaElement && metaElement.getAttribute('content');

    return metaValue !== undefined ? metaValue : null;
}

export default function getMeta(name: string): string | null {
    return getMetaFromHTML(name);
}

export const setHTMLMeta = (name: string, value: string): void => {
    let node = document.querySelector(`meta[name="${name}"]`);
    if (!node) {
        node = document.createElement('meta');
        node.setAttribute('name', name);
        document.head.appendChild(node);
    }
    node.setAttribute('content', value);
};

export const getCloudId = (): string => {
    return getMeta('tenant-id') || '';
};

export const getActivationId = () => getMeta('activation-id') ?? '';

export const getHelpCenterAri = (): string => {
    return getMeta('help-center-ari') || '';
};

export const getWorkspaceAri = (): string => {
    return getMeta('jira-workspace-ari') || '';
};

export const getLayoutAri = (): string => {
    return getMeta('home-page-layout-ari') || '';
};

export const isValidLayoutAriPresent = (): boolean => {
    const layoutAri = getLayoutAri();
    return layoutAri !== '' && layoutAri !== 'null';
};

export const addMetaToHead = (name: string, value: string) => {
    const existingMetaTag = document.querySelector(`meta[name="${name}"]`);

    if (existingMetaTag?.getAttribute('content') === value) {
        return;
    }

    const metaTag = existingMetaTag || document.createElement('meta');

    metaTag.setAttribute('name', name);
    metaTag.setAttribute('content', value);

    document.head?.insertBefore(metaTag, document.head.childNodes[0]);
};
