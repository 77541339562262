import * as React from 'react';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { Loading } from '@atlassian/help-center-common-component/loading';

interface BaseLoadingContainerProps {
    loadingPosition?: 'static' | 'fill-container';
    isLoading: boolean;
    interactionName?: string;
    children: React.ReactNode;
}

interface UFOHoldEnabledProps extends BaseLoadingContainerProps {
    enableUFOHold: true;
    ufoHoldName: string;
}

interface UFOHoldDisabledProps extends BaseLoadingContainerProps {
    enableUFOHold?: false;
    ufoHoldName?: never;
}

export type LoadingContainerProps = UFOHoldEnabledProps | UFOHoldDisabledProps;

/**
 * Displays children when isLoading is false, otherwise shows loading spinner.
 */
export default class LoadingContainer extends React.Component<LoadingContainerProps> {
    static defaultProps = {
        loadingPosition: 'fill-container',
    };

    render() {
        const { children, loadingPosition, isLoading, interactionName, enableUFOHold, ufoHoldName } = this.props;

        const loader = <Loading isLoading loadingPosition={loadingPosition} interactionName={interactionName} />;

        if (!isLoading) return children;

        return enableUFOHold ? <UFOLoadHold name={ufoHoldName}>{loader}</UFOLoadHold> : loader;
    }
}
