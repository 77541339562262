import * as React from 'react';
import type { Option, ValueType } from '@atlaskit/select';
import type { FieldFormProps } from '@atlassian/help-center-common-component/fields/types';
import { FormSingleSelect } from '@atlassian/help-center-common-component/form-select';
import { toSingleSelectValue } from '@atlassian/help-center-common-util/select';
import { isJsmUimOnChangeOnBlurListenersEnabled } from '../../../../feature-flags';

type Props = FieldFormProps<string> & {
    label: string;
    defaultValue?: Option<string>;
    valueOverride?: Option<string>;
    options: Option<string>[];
    menuPortalTarget?: HTMLElement;
};

class SingleFieldSelect extends React.PureComponent<Props> {
    componentDidMount() {
        // JDW-2280 - this is required to make url filling (e.g. ?customfield_10343=10657) work
        if (this.props.defaultValue) {
            this.onChange(this.props.defaultValue);
        }
    }

    onChange = (option: ValueType<Option<string>>) => {
        this.props.onChange?.(this.props.id, toSingleSelectValue(option)?.value ?? '');
    };

    onChangeNew = (option: ValueType<Option<string>>, meta?: object) => {
        this.props.onChange?.(this.props.id, toSingleSelectValue(option)?.value ?? '', false, {
            rawObject: meta ?? {},
            rawValue: option,
        });
    };

    render() {
        const { disabled, ...otherProps } = this.props;

        return (
            <FormSingleSelect
                {...otherProps}
                onChange={isJsmUimOnChangeOnBlurListenersEnabled() ? this.onChangeNew : this.onChange}
                isDisabled={disabled}
            />
        );
    }
}

export default SingleFieldSelect;
